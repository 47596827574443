import { useMemo } from 'react'
import { useLocation, matchPath } from 'react-router-dom'
import type { Location } from 'history'
import type { match } from 'react-router-dom'
import type {
	RouteConfig as BaseRouteConfig,
	MatchedRoute as BaseMatchedRoute,
} from 'react-router-config'
import type { QueryClient } from 'react-query'
import type { HelmetProps } from 'react-helmet-async'

export type PrefetchFunction<Params extends Record<string, string>> = (
	match: match<Params>,
	location: Location,
	client: QueryClient
) => Promise<void>

export type RouteConfig<Params extends Record<string, string>> =
	BaseRouteConfig & {
		prefetchQueries?: PrefetchFunction<Params>
		helmet?: () => HelmetProps
	}

export type MatchedRoute<Params extends Record<string, string>> =
	BaseMatchedRoute<Params, RouteConfig<Params>>

export const useSearchParams: () => URLSearchParams = () => {
	const location = useLocation()
	return useMemo(() => new URLSearchParams(location.search), [location])
}

/**
 * This function is used in the server to run the prefetch functions of all the routes that matches
 */
export function prefetchReactQuery<Params extends Record<string, string>>(
	branch: MatchedRoute<Params>[],
	location: Location,
	client: QueryClient
): Promise<unknown> {
	const promises = []
	for (const { route } of branch) {
		if (route.prefetchQueries) {
			const match = matchPath<Params>(location.pathname, route)
			if (match === null) {
				continue
			}
			promises.push(route.prefetchQueries(match, location, client))
		}
	}
	return Promise.allSettled(promises)
}
