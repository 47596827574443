import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

export const COWORKING_TYPE = 'coworking-spaces'
export const SERVICED_OFFICE_TYPE = 'lediga-kontorshotell'
export const OFFICE_TYPE = 'lediga-kontor'

export type TYPE_CHOICE =
	| typeof COWORKING_TYPE
	| typeof SERVICED_OFFICE_TYPE
	| typeof OFFICE_TYPE

export const SORT_RECOMMENDED = 'recommended'
export const SORT_ASC_PRICE = 'price_asc'
export const SORT_DESC_PRICE = 'price_desc'

export type SORTING_CHOICE =
	| typeof SORT_RECOMMENDED
	| typeof SORT_ASC_PRICE
	| typeof SORT_DESC_PRICE

export const FILTER_TYPE_ALL = 'all'
export const FILTER_TYPE_OFFICE = 'office'
export const FILTER_TYPE_MEMBERSHIP = 'membership'

export type FILTER_TYPE_CHOICE =
	| typeof FILTER_TYPE_ALL
	| typeof FILTER_TYPE_OFFICE
	| typeof FILTER_TYPE_MEMBERSHIP

/**
 * Given any input, returns the sorting we will want to use. If the sorting is known it uses it otherwise returns a default
 */
export function parseSorting(input: string): SORTING_CHOICE {
	if (
		[SORT_RECOMMENDED, SORT_ASC_PRICE, SORT_DESC_PRICE].indexOf(input) === -1
	) {
		return SORT_RECOMMENDED
	}
	return input as SORTING_CHOICE
}

export function parseFilterType(input: string | undefined): FILTER_TYPE_CHOICE {
	if (
		input === undefined ||
		(input &&
			[FILTER_TYPE_ALL, FILTER_TYPE_OFFICE, FILTER_TYPE_MEMBERSHIP].indexOf(
				input
			) === -1)
	) {
		return FILTER_TYPE_ALL
	}
	return input as FILTER_TYPE_CHOICE
}

export type SerpFilters = {
	type: TYPE_CHOICE
	areaCodes: string[]
	services: string[]
	maxBudget: number | null
	minWorkspaces: number | null
	sorting: SORTING_CHOICE
	filterType?: FILTER_TYPE_CHOICE | undefined
	area_url?: string | undefined
}

/**
 * This hook returns a function that can generate valid URLs for SERP pages based on types
 * and filter parameters. It is a hook so that we can use the nice business area URL when
 * only a single area has been selected. This way we bypass the problem of keeping a nice URL
 * if you update the serp from /lediga-kotnro/stockholm/södermalm and add a budget for instance
 */
export const useSerpUrlGenerator: () => (
	filters: SerpFilters
) => string = () => {
	const { t, i18n } = useTranslation()
	return useCallback(
		({
			type = OFFICE_TYPE,
			areaCodes,
			services,
			maxBudget = null,
			minWorkspaces = null,
			sorting = SORT_RECOMMENDED,
			filterType,
			area_url,
		}: SerpFilters) => {
			const searchParams = new URLSearchParams()
			if (minWorkspaces) {
				searchParams.set('ws', minWorkspaces.toString())
			} else {
				searchParams.delete('ws')
			}
			if (maxBudget) {
				searchParams.set('budget', maxBudget.toString())
			} else {
				searchParams.delete('budget')
			}

			if (
				filterType &&
				[FILTER_TYPE_OFFICE, FILTER_TYPE_MEMBERSHIP, FILTER_TYPE_ALL].includes(
					filterType
				)
			) {
				searchParams.set('type', filterType)
			} else {
				searchParams.delete('type')
			}

			searchParams.delete('area')
			for (const area of areaCodes) {
				searchParams.append('area', area)
			}

			searchParams.delete('service')
			for (const serviceCode of services) {
				searchParams.append('service', serviceCode)
			}

			if (sorting !== SORT_RECOMMENDED) {
				searchParams.set('sort', sorting)
			} else {
				searchParams.delete('sort')
			}
			const pathSegments: string[] = [t(type, { ns: 'url' })]
			if (i18n.language !== 'sv') {
				pathSegments.unshift(i18n.language)
			}
			if (!areaCodes.length && area_url) {
				pathSegments.push(area_url)
			}
			return `/${pathSegments.join('/')}?${searchParams.toString()}`
		},
		[t, i18n.language]
	)
}
