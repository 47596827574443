import { useForm, EmailField } from '@alexanderathoodly/formify'
import { apiClient } from '~/utils/requests'
import { useMutation } from 'react-query'

interface UsePasswordResetEmailForm {
	onSubmit: () => void
	email: string
	onChangeEmail: (email: string) => void
	isFormValid: boolean
	isSendMailSuccess: boolean
	isSendMailError: boolean
	error: unknown
	formReset: () => void
}

export function usePasswordResetEmailForm(): UsePasswordResetEmailForm {
	const sendPasswordResetEmailQuery = useMutation(
		async ({
			email,
			continueUrl,
			domain,
		}: {
			email: string
			continueUrl: string
			domain: string
		}) => await apiClient.resetPassword(email, continueUrl, domain)
	)

	const onFormSubmit = (formValue: { email: string }) => {
		const uri = new URL(window.location.href)
		sendPasswordResetEmailQuery.mutate({
			email: formValue.email,
			continueUrl: window.location.href,
			domain: uri.host,
		})
	}

	const formReset = () => {
		sendPasswordResetEmailQuery.reset()
		form.setValue('email', '')
	}

	const form = useForm({
		fields: {
			email: EmailField({ required: true }),
		},
		onFormSubmit,
	})

	return {
		onSubmit: form.trySubmit,
		email: form.getValue('email'),
		onChangeEmail: (email: string) => form.setValue('email', email),
		isFormValid: form.isFormValid(),
		isSendMailSuccess: sendPasswordResetEmailQuery.isSuccess,
		isSendMailError: sendPasswordResetEmailQuery.isError,
		error: sendPasswordResetEmailQuery.error,
		formReset,
	}
}
