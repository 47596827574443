import { Suspense } from 'react'
import * as settings from './settings'
import './helper'
import { hydrateRoot } from 'react-dom/client'
import { QueryClient, QueryClientProvider, Hydrate } from 'react-query'
import ScrollManager from './scrollmanager'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter as Router } from 'react-router-dom'
import { renderRoutes } from 'react-router-config'
import routes from './routes'
import { FirebaseConfig } from '~/auth/config'
import { initializeAnalytics } from './analytics/initializeAnalytics'
import SyncQueryCacheStateToExtension from './SyncQueryCacheStateToExtension'
import StackdriverErrorReporter from 'stackdriver-errors-js'
import ErrorBoundary from './ErrorBoundary'
import 'mapbox-gl/dist/mapbox-gl.css'
import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
import { RouterProvider } from './routercontext'
import { LoadingPage } from './layouts/pages'
import i18n from './i18n'
import I18nProvider from './i18nprovider'
import {
	YtaClientProvider,
	YtaApiClientV2,
} from '@alexanderathoodly/data-models'

/*
This is the entry point in the browser. polyfills should be at the top to ensure they are loaded when the app runs.
The main task is to load the data and create the app so that the state from the server is restored gracefully.
*/

// Removes the react-helmet attribute from the robots tag to avoid that react helmet removes it
// Right now the robots tag is generated only from the server and the client does not have access to the code needed (the list of indexed SERPs)
const robotTag = document.querySelector('meta[name=robots]')
robotTag && robotTag.removeAttribute('data-react-helmet')

window.settings = settings

// Load GA script
initializeAnalytics()

// Prepare React query
const queryClient = new QueryClient()
const reactQueryDebydratedState = window.__REACT_QUERY_STATE__
const ytaApiClientV2 = new YtaApiClientV2({
	staging: import.meta.env.MODE != 'production',
})

// Prepare internationalization
const initialI18nStore = window.__INITIAL_I18N_STORE__
const initialLanguage = window.__INITIAL_LANGUAGE__

// Needed to avoid running the app in the Google cache, otherwise frontend routing will render an empty page
const isCached = document.getElementsByTagName('base').length > 0

performance.mark('Own JS loaded')

const errorHandler = new StackdriverErrorReporter()
errorHandler.start({
	key: import.meta.env.VITE_FIREBASE_APIKEY,
	projectId: import.meta.env.VITE_PROJECT_ID,
	disabled: import.meta.env.MODE == 'development',
})

const key = 'yta-frontend'
const cache = createCache({ key })

const container = document.getElementById('app')

if (!isCached && container) {
	hydrateRoot(
		container,
		<HelmetProvider>
			<QueryClientProvider client={queryClient}>
				<YtaClientProvider value={ytaApiClientV2}>
					<SyncQueryCacheStateToExtension>
						<Hydrate state={reactQueryDebydratedState}>
							<CacheProvider value={cache}>
								<FirebaseConfig>
									<I18nProvider
										i18n={i18n}
										i18nStore={initialI18nStore}
										initialLanguage={initialLanguage}
									>
										<Router>
											<ErrorBoundary errorHandler={errorHandler}>
												<ScrollManager>
													<Suspense fallback={<LoadingPage />}>
														<RouterProvider>
															{renderRoutes(routes)}
														</RouterProvider>
													</Suspense>
												</ScrollManager>
											</ErrorBoundary>
										</Router>
									</I18nProvider>
								</FirebaseConfig>
							</CacheProvider>
						</Hydrate>
					</SyncQueryCacheStateToExtension>
				</YtaClientProvider>
			</QueryClientProvider>
		</HelmetProvider>
	)
}

// Only for Cypress, know when hydration is complete
typeof window.requestIdleCallback === 'function' &&
	window.requestIdleCallback(() => {
		setTimeout(() => {
			window.hydrationComplete = true
		}, 10)
	})
