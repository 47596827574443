import { lazy } from 'react'
import type { RouteConfig } from '~/utils/routing'

const Layout = lazy(() => import('./layout'))

const route: RouteConfig<{ invitationId: string }> = {
	path: '/invitation/:invitationId',
	exact: true,
	component: Layout,
}

export default route
