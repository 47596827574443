/**
 * When we need to make queries using the current user we need to extract
 * a JWT token from the firebase user
 * ReactFire has a `useIdTokenResult` method but it cannot be conditionnal (takes a user as input) and runs at render time.
 * We want to get the token when doing the request.
 * This module wraps a query builder so that the query function will retrieve a JWT token an insert it in the context
 * At the same time, we also inject queryClient to the factory because we need it pretty often to check
 * or invalidate the cache in the query / mutation
 */
import { useMemo } from 'react'
import { useQuery, useQueryClient, useMutation } from 'react-query'
import type {
	QueryClient,
	UseQueryOptions,
	UseQueryResult,
	UseMutationResult,
	UseMutationOptions,
	MutationFunction,
	QueryKey,
	QueryFunctionContext,
	QueryFunction,
} from 'react-query'
import { useUser } from 'reactfire'

export interface AuthQueryFunctionContext<
	TQueryKey extends QueryKey = QueryKey,
	TPageParam = any
> extends QueryFunctionContext<TQueryKey, TPageParam> {
	jwtToken: string
}

export type AuthQueryFunction<
	T = unknown,
	TQueryKey extends QueryKey = QueryKey
> = (context: AuthQueryFunctionContext<TQueryKey>) => T | Promise<T>

interface UnpreparedUseQueryOptions<
	TQueryFnData = unknown,
	TData = TQueryFnData,
	TQueryKey extends QueryKey = QueryKey
> extends Omit<
		UseQueryOptions<TQueryFnData, Error, TData, TQueryKey>,
		'queryFn' | 'onSuccess' | 'onSettled' // TODO: not to excluse onSuccess and onSettled we would need a more complex type setup for selectors because TData plays a role in them
	> {
	queryFn: AuthQueryFunction<TQueryFnData, TQueryKey>
}

export type AuthQueryFactory<
	Targs extends any[] = [],
	TQueryFnData = unknown,
	TData = TQueryFnData,
	TQueryKey extends QueryKey = QueryKey
> = (
	c: {
		queryClient: QueryClient
	},
	...args: Targs
) => UnpreparedUseQueryOptions<TQueryFnData, TData, TQueryKey>

export function useAuthQuery<
	TArgs extends any[],
	TQueryFnData = unknown,
	TData = TQueryFnData,
	TQueryKey extends QueryKey = QueryKey
>(
	queryFactory: AuthQueryFactory<TArgs, TQueryFnData, TData, TQueryKey>,
	...args: TArgs
): UseQueryResult<TData, Error> {
	const user = useUser()
	const queryClient = useQueryClient()
	const queryOpts: UseQueryOptions<TQueryFnData, Error, TData, TQueryKey> =
		useMemo(() => {
			const unpreparedQueryOpts = queryFactory({ queryClient }, ...args)
			const enabledIfUser = !!user.data
			const newEnabled = unpreparedQueryOpts.enabled
				? unpreparedQueryOpts.enabled && enabledIfUser
				: enabledIfUser
			const newQueryFn: QueryFunction<TQueryFnData, TQueryKey> = async (
				context
			) => {
				if (!user.data) {
					throw new Error('User not logged in')
				}
				const jwtToken = await user.data.getIdToken()
				return await unpreparedQueryOpts.queryFn({ ...context, jwtToken })
			}
			return {
				...unpreparedQueryOpts,
				queryFn: newQueryFn,
				enabled: newEnabled,
			}
		}, [user, queryClient, queryFactory, args])
	return useQuery(queryOpts)
}

interface UnpreparedUseMutationOptions<
	TData = unknown,
	TVariables = void,
	TContext = unknown
> extends Omit<
		UseMutationOptions<TData, Error, TVariables, TContext>,
		'mutationFn'
	> {
	mutationFn: (
		context: {
			jwtToken: string
		},
		variables: TVariables
	) => Promise<TData>
}

export type AuthMutationFactory<
	TData = unknown,
	TVariables = void,
	TContext = unknown
> = (c: {
	queryClient: QueryClient
}) => UnpreparedUseMutationOptions<TData, TVariables, TContext>

function useAuthMutationOpts<TData = unknown, TVariables = void>(
	mutationFactory: AuthMutationFactory<TData, TVariables>
): UseMutationOptions<TData, Error, TVariables> {
	const user = useUser()
	const queryClient = useQueryClient()
	return useMemo(() => {
		const unpreparedOpts = mutationFactory({ queryClient })
		const newMutationFn: MutationFunction<TData, TVariables> = async (
			variables
		) => {
			if (!user.data) {
				throw new Error('User not logged in')
			}
			const jwtToken = await user.data.getIdToken()
			return await unpreparedOpts.mutationFn({ jwtToken }, variables)
		}
		return {
			...unpreparedOpts,
			mutationFn: newMutationFn,
		}
	}, [user, mutationFactory, queryClient])
}

export function useAuthMutation<TData = unknown, TVariables = void>(
	mutationFactory: AuthMutationFactory<TData, TVariables>
): UseMutationResult<TData, Error, TVariables> {
	const mutationOpts = useAuthMutationOpts(mutationFactory)
	return useMutation(mutationOpts)
}
