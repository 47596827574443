import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useRef,
} from 'react'
import type { FC, ReactNode } from 'react'
import { useHistory } from 'react-router-dom'
import type { UnpackNestedValue, DeepPartial } from 'react-hook-form'
import {
	useSerpUrlGenerator,
	FILTER_TYPE_ALL,
	FILTER_TYPE_MEMBERSHIP,
	FILTER_TYPE_OFFICE,
} from './urlGenerator'
import type { SerpFilters } from './urlGenerator'
import { CoworkingSearchParams, useSerpPageTypeInSwedish } from './utils'
import { useCoworkingSerpParams } from './utils'
import { useQuery } from 'react-query'
import { getCoworkingSearchResultQuery } from '~/queries/coworking_search_result'

const EDIT_SESSION_PASSIVE_EXPIRATION = 5000
const MIN_TIME_BETWEEN_REQUESTS = 500

/**
 * When updating filters in the SERP we have "update session". An update session starts the first time
 * you edit any of the filtering options, and ends when nothing has been editing for some time.
 * The session allow us to push a single entry in the history instead of pushing several (sometimes dozens)
 * of entries as you update the filters. It works by doing a single push for the first change, then using replace
 * for the following updates.
 * In addition, updates to the URL are throttled so that we don't change it too often which could cause rendering
 * and request troubles.
 */
type PartialSerpFilter = UnpackNestedValue<DeepPartial<SerpFilters>>
type PartialFilterUpdate = (filters: PartialSerpFilter) => void

const SerpFilterUpdateContext = createContext<PartialFilterUpdate>(() => {
	//Noop, will be provided later
})

export const SerpFilterUpdateSessionProvider: FC<{
	children?: ReactNode
}> = ({ children }) => {
	const history = useHistory()
	const editSession = useRef<number | null>(null) // Timeout ID for the current edit session finalization
	const lastUpdate = useRef<number | null>(null) // Timeout ID for the current throttle action finalization
	const searchParams = useCoworkingSerpParams()
	const serpType = useSerpPageTypeInSwedish()
	const currentSearchparams = useRef<CoworkingSearchParams>(searchParams) // Saved as a ref not to trigger additional rendering when things change
	const maxSolutionPrice = useRef<number | null>(null) // To judge budget param is equal to max solution price
	const serpResult = useQuery(
		getCoworkingSearchResultQuery({
			...searchParams,
			budget: undefined,
			type:
				searchParams.type !== FILTER_TYPE_OFFICE &&
				searchParams.type !== FILTER_TYPE_MEMBERSHIP
					? undefined
					: searchParams.type,
		})
	)

	useEffect(() => {
		currentSearchparams.current = searchParams
	}, [searchParams])

	useEffect(() => {
		if (serpResult.data) {
			maxSolutionPrice.current =
				Math.ceil(
					Math.max(
						...serpResult.data.locations.map((location) => {
							const membershipPrice = location.min_rent_membership?.price || 0
							const solutionPrice = location.min_rent_solution?.price || 0
							if (searchParams.type) {
								if (searchParams.type == FILTER_TYPE_MEMBERSHIP) {
									return searchParams.workspaces
										? membershipPrice * searchParams.workspaces
										: membershipPrice
								}
								if (searchParams.type == FILTER_TYPE_OFFICE) {
									return solutionPrice
								}
							}
							return Math.max(
								searchParams.workspaces
									? membershipPrice * searchParams.workspaces
									: membershipPrice,
								solutionPrice
							)
						})
					) / 100
				) * 100
		}
	}, [serpResult.data, searchParams.type, searchParams.workspaces])

	const URLGenerator = useSerpUrlGenerator()

	const finishSession = useCallback(() => {
		editSession.current = null
	}, [])

	const refreshSession = useCallback(() => {
		if (editSession.current) {
			window.clearTimeout(editSession.current)
		}
		editSession.current = window.setTimeout(
			finishSession,
			EDIT_SESSION_PASSIVE_EXPIRATION
		)
	}, [finishSession])

	const delayUpdate = useCallback(
		(newUrl: string) => {
			if (lastUpdate.current) {
				clearTimeout(lastUpdate.current)
			}
			lastUpdate.current = window.setTimeout(() => {
				lastUpdate.current = null
				history.replace(newUrl)
			}, MIN_TIME_BETWEEN_REQUESTS)
		},
		[history]
	)

	const updateFunction = useCallback(
		(f: PartialSerpFilter) => {
			const isNewSession = editSession.current === null
			const p = currentSearchparams.current
			const newUrl = URLGenerator({
				maxBudget:
					f.maxBudget == maxSolutionPrice.current
						? null
						: f.maxBudget === undefined
						? p.budget || null
						: f.maxBudget,
				type: serpType,
				areaCodes: (f?.areaCodes as string[]) || [], // Type cast because otherwise react-hooks-form believes we can have undefined inside the array
				sorting: f.sorting || p.sorting,
				minWorkspaces:
					f.minWorkspaces === undefined
						? p.workspaces || null
						: f.minWorkspaces,
				services: (f.services as string[]) || [],
				filterType:
					p.type === undefined && f.filterType === FILTER_TYPE_ALL
						? undefined
						: f.filterType || p.type,
				area_url: p.area_url,
			})
			// We need to compare URLs without page parameter
			const searchParams = new URLSearchParams(window.location.search)
			if (searchParams.has('page')) {
				searchParams.delete('page')
			}
			const currentUrl = new URL(
				`${window.location.origin}${
					window.location.pathname
				}?${searchParams.toString()}`
			)
			if (
				new URL(newUrl, window.location.toString()).toString() ===
				currentUrl.toString()
			) {
				// No need to schedule an update if the current URL matches the destination
				// We compare by using the URL constructor so that URL encoding / decoding is consistent on both sides, for instance göteborg -> g%C3%B6teborg
				return
			}
			if (isNewSession) {
				history.push(newUrl)
			} else {
				delayUpdate(newUrl)
			}
			refreshSession()
		},
		[URLGenerator, history, delayUpdate, refreshSession]
	)

	return (
		<SerpFilterUpdateContext.Provider value={updateFunction}>
			{children}
		</SerpFilterUpdateContext.Provider>
	)
}

export const useUpdateSerpFilters = () => useContext(SerpFilterUpdateContext)
