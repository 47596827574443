import type { FC, PropsWithChildren } from 'react'
import { useUser } from 'reactfire'
import {
	Heading,
	Icon,
	Text,
	Container,
	Divider,
	Link,
	Box,
	Grid,
	ChakraComponent,
	Button,
	Spinner,
} from '@chakra-ui/react'
import {
	AppWindowError404Icn as Warning404,
	Warning500Icn as Warning500,
	RoadSignBannedIcn,
} from '@alexanderathoodly/ui-library'
import { SignInUpForm } from '~/auth/signinupform'
import { Header } from './header/header'
import { Footer } from './footer/footer'
import { Trans, useTranslation } from 'react-i18next'

const PageBaseLayout: FC<PropsWithChildren> = ({ children }) => {
	return (
		<>
			<Header />
			{children}
			<Footer />
		</>
	)
}

export const PublicPageLayout = PageBaseLayout

export const PrivatePageLayout: FC<PropsWithChildren> = ({ children }) => {
	const { t } = useTranslation()
	const user = useUser()
	if (user === null || !user.data) {
		return (
			<PageBaseLayout>
				<Container maxW="container.sm" mb={10}>
					<Heading mb={7}>
						{t('Du behöver vara inloggad för att nå denna sida')}
					</Heading>
					<SignInUpForm defaultType="signIn" />
				</Container>
			</PageBaseLayout>
		)
	}
	return <PageBaseLayout>{children}</PageBaseLayout>
}

function getErrorIcon(errorCode: number | undefined) {
	switch (errorCode) {
		case 500:
			return Warning500
		case 404:
			return Warning404
		default:
			return Warning500
	}
}

const ErrorText: ChakraComponent<'p', { errorCode: number | undefined }> = ({
	errorCode,
	...rest
}) => {
	const { t } = useTranslation()
	return errorCode == 404 ? (
		<Text {...rest}>
			{t(
				'Sidan kan inte hittas. Det kanske är en gammal länk eller har denna sidan flyttats.'
			)}
		</Text>
	) : (
		<Text {...rest}>
			<Trans
				i18nKey="Tyvärr uppstod ett fel. Försök igen eller kontakta oss."
				defaults="Tyvärr uppstod ett fel. Försök igen eller <customlink>kontakta oss</customlink>."
				components={{
					customlink: <Link variant="blue" href="/om" />,
				}}
			/>
		</Text>
	)
}

interface ErrorPageProps {
	error?: Error | null
	code?: number
}

export const ErrorPage: FC<ErrorPageProps> = ({ error, code }) => {
	const { t } = useTranslation()
	return (
		<PageBaseLayout>
			<Container textAlign="center">
				<Grid
					templateRows="repeat(2, min-content)"
					templateColumns="min-content auto"
					columnGap={10}
					mb={5}
				>
					<Icon
						as={getErrorIcon(code)}
						color="red.500"
						w={{ base: 14, md: 20 }}
						h={{ base: 14, md: 20 }}
						gridRow="1/3"
						gridColumn="1/2"
						justifySelf="center"
						alignSelf="center"
					/>
					<Heading
						fontSize="4xl"
						gridRow="1/2"
						gridColumn="2/3"
						justifySelf="self-start"
						alignSelf="center"
					>
						{t('Fel!')}
					</Heading>
					<ErrorText
						errorCode={code}
						fontSize="lg"
						gridRow="2/3"
						gridColumn="2/3"
						textAlign="left"
					/>
				</Grid>
				{error && (
					<Text mb={10} textAlign="left">
						{t('Fel')}: {error.message}
					</Text>
				)}
				<Divider orientation="horizontal" mb={10} />
				<Text mb={10} textAlign="left">
					{t(
						'Letar du lediga lokaler? yta.se är en marknadsplats för lediga lokaler & kontor. Tyvärr kunde vi inte hitta just denna sida, det kan bero på att du skrivit in fel webbadress eller att sidan inte längre finns. Använd gärna snabbknapparna nedan till vår tjänst för att hitta den perfekta lokalen för just ert företag.'
					)}
				</Text>
				<Box textAlign="left">
					<Link variant="blue" href="/">
						{t('Gå till startsidan')} -{' '}
					</Link>
					<Link variant="blue" href="/om">
						{t('Kontakta yta.se')} -{' '}
					</Link>
					<Link variant="blue" href="/lediga-kontorshotell/stockholm">
						{t('Kontorshotell')} Stockholm -{' '}
					</Link>
					<Link variant="blue" href="/lediga-kontorshotell/göteborg">
						{t('Kontorshotell')} Göteborg -{' '}
					</Link>
					<Link variant="blue" href="/lediga-kontorshotell/malmö">
						{t('Kontorshotell')} Malmö -{' '}
					</Link>
					<Link variant="blue" href="/lediga-kontorshotell/lund">
						{t('Kontorshotell')} Lund
					</Link>
				</Box>
			</Container>
		</PageBaseLayout>
	)
}

export const UnauthorizedPage: FC = () => {
	const user = useUser()

	return (
		<PageBaseLayout>
			<Container
				color="orange.500"
				py={10}
				maxW="container.lg"
				textAlign="center"
			>
				<Icon as={RoadSignBannedIcn} w={20} h={20} mb={4} />
				<Heading>Unauthorized</Heading>
				<Text>You do not have the required permissions to view this page.</Text>
				<Divider orientation="horizontal" my={5} />
				<Text>User: {user.data?.email}</Text>
				<Text>URL: {window.location.href}</Text>
			</Container>
		</PageBaseLayout>
	)
}

export const LazyModuleErrorPage: FC = () => {
	const { t } = useTranslation()
	return (
		<PublicPageLayout>
			<Container>
				<Heading mb={1}>{t('Sidan har uppdaterats.')}</Heading>
				<Text mb={10} fontSize="xl">
					{t('Uppdatera sidan för att få det senaste innehållet.')}
				</Text>
				<Button
					w="full"
					mb={10}
					onClick={() => {
						window.location.reload()
					}}
				>
					{t('Uppdatera sidan')}
				</Button>
				<Text fontSize="xl">
					{t(
						'Kontakta oss ifall sidan inte uppdateras även om du försöker flera gånger.'
					)}
				</Text>
			</Container>
		</PublicPageLayout>
	)
}

export const LoadingPage: FC = () => {
	return (
		<Container centerContent marginTop="50vh">
			<Spinner w={30} h={30} />
			<Text mt={2}>Loading...</Text>
		</Container>
	)
}
