import type { FC } from 'react'
import {
	Box,
	chakra,
	Container,
	Divider,
	Flex,
	Grid,
	Text,
	Icon,
	Button,
	Link as ChakraLink,
} from '@chakra-ui/react'
import type { ChakraComponent } from '@chakra-ui/react'
import { ReactComponent as Logo } from '#/yta-logo-neutral.svg'
import { ReactComponent as LinkedinIcon } from '#/linked-in-icon.svg'
import { ReactComponent as Facebook } from '#/facebook-icon.svg'
import {
	DEFAULT_PHONE_NUMBER_INTERNATIONAL,
	PHONE_NUMBER_LINK,
} from '~/settings'
import { Link as RouterLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useLinks } from './uselinks'
import type { Link } from './uselinks'

const LinkSection = chakra('section', { baseStyle: {} })

const LinkSectionHeading = chakra('h1', {
	baseStyle: { mb: 5, color: 'blue.500', fontWeight: 'semibold' },
})

const getRel = (link: Link): string | undefined => {
	if ('noFollow' in link) {
		if ('isExternal' in link && link.isExternal) {
			return 'nofollow noopener'
		} else {
			return 'nofollow'
		}
	} else {
		if ('isExternal' in link && link.isExternal) {
			return 'noopener'
		} else {
			return undefined
		}
	}
}

const footerLinkStyle = {
	color: 'grey.400',
	fontWeight: 'normal',
	display: 'block',
	_hover: {
		color: 'grey.500',
	},
}
/**
 *
 * All footer links are currently using standard links and not frontend routing because detecting if the transition is safe is
 * too complicated.
 * TODO: change the code to use frontend routing once we have a safe routing between all sides of the frontend
 */
const FooterLink: ChakraComponent<'a', { link: Link }> = ({
	link,
	...rest
}) => {
	return (
		<Box
			as="a"
			{...footerLinkStyle}
			{...rest}
			href={'href' in link ? link.href : link.to}
			rel={getRel(link)}
			target={'isExternal' in link && link.isExternal ? '_blank' : undefined}
		>
			{link.title}
		</Box>
	)
}

export const Footer: FC = () => {
	const { t } = useTranslation()
	const links = useLinks()
	const currentYear = new Date().getFullYear()
	return (
		<Box as="footer" bg="#eceff4" pt={24} pb={10} mt={24}>
			<Container maxW="container.lg">
				<ChakraLink as={RouterLink} to="/" display="block">
					<Icon
						as={Logo}
						color="blue.500"
						display="block"
						h={7}
						w="auto"
						mb={10}
						mx={{ base: 'auto', md: 0 }}
					/>
				</ChakraLink>
				<Grid
					columnGap={10}
					templateColumns={{ base: '1fr', md: 'repeat(4, 1fr)' }}
					mb={24}
				>
					<Flex
						direction="column"
						rowGap="40px"
						textAlign={{ base: 'center', md: 'left' }}
					>
						<LinkSection display={{ base: 'block', md: 'none' }}>
							<LinkSectionHeading>
								{t('Hitta lediga kontor')}
							</LinkSectionHeading>
							<Flex direction="column" rowGap={2}>
								{links.mobileFooterLinks.map((link) => (
									<FooterLink key={link.title} link={link} />
								))}
							</Flex>
						</LinkSection>
						<LinkSection>
							<LinkSectionHeading>{t('Kontakta oss')}</LinkSectionHeading>
							<Flex direction="column" rowGap="20px">
								<Button as="a" href="mailto:info@yta.se" w="full">
									{t('E-post')}
								</Button>
								<Button
									as="a"
									href={PHONE_NUMBER_LINK}
									variant="outline"
									w="full"
								>
									{DEFAULT_PHONE_NUMBER_INTERNATIONAL}
								</Button>
							</Flex>
						</LinkSection>
						<LinkSection>
							<LinkSectionHeading>{t('Hitta oss')}</LinkSectionHeading>
							<Flex
								columnGap="30px"
								justifyContent={{ base: 'center', md: 'start' }}
							>
								<a href="https://www.facebook.com/ytapunktse">
									<Icon as={Facebook} color="grey.300" h={5} w="auto" />
								</a>
								<a href="https://www.linkedin.com/company/17884774/">
									<Icon as={LinkedinIcon} color="grey.300" h={5} w="auto" />
								</a>
							</Flex>
						</LinkSection>
					</Flex>
					<LinkSection display={{ base: 'none', md: 'block' }}>
						<LinkSectionHeading>{t('Hitta lediga kontor')}</LinkSectionHeading>
						{links.internalLinks.map((l, i) => (
							<FooterLink key={i} link={l} />
						))}
					</LinkSection>
					<LinkSection display={{ base: 'none', md: 'block' }}>
						<LinkSectionHeading>{t('Lär dig mer')}</LinkSectionHeading>
						{links.blogLinks.map((l, i) => (
							<FooterLink key={i} link={l} />
						))}
					</LinkSection>
					<LinkSection display={{ base: 'none', md: 'block' }}>
						<LinkSectionHeading>{t('Om yta.se')}</LinkSectionHeading>
						{links.infoLinks.map((l, i) => (
							<FooterLink key={i} link={l} />
						))}
					</LinkSection>
				</Grid>
				<Divider borderColor="grey.100" mb={7}></Divider>
				<Flex
					direction={{ base: 'column', md: 'row' }}
					alignItems="center"
					rowGap="5px"
				>
					<Text color="grey.400">Copyright © {currentYear} Objektia AB</Text>
					<FooterLink
						link={{ to: '/data-policy', title: t('Personuppgiftspolicy') }}
						ml={{ base: 0, md: 'auto' }}
					/>
					<Button
						variant="link"
						{...footerLinkStyle}
						fontSize="14px"
						ml={{ base: 0, md: 'auto' }}
						onClick={(e) => {
							e.preventDefault()
							window.Cookiebot.show(true)
						}}
					>
						{t('Hantera cookies')}
					</Button>
				</Flex>
			</Container>
		</Box>
	)
}
