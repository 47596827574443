import type { QueryClient, QueryFunction, UseQueryOptions } from 'react-query'
import type {
	LocationResult,
	FloorResult,
	SimilarLocations,
} from '@alexanderathoodly/data-models'
import { apiClient } from '~/utils/requests'

type LocationsQueryKey = 'locations'

const fetchLocations: QueryFunction<
	LocationResult[],
	LocationsQueryKey
> = async () => {
	return await apiClient.getServicedOfficeLocations()
}

export const locationsQuery: UseQueryOptions<
	LocationResult[],
	Error,
	LocationResult[],
	LocationsQueryKey
> = {
	queryKey: 'locations',
	queryFn: fetchLocations,
	staleTime: 15 * 60 * 1000, // 15 minutes
}

type SimilarLocationsQueryKey = [
	'similar_locations',
	{ locationId: string | undefined }
]

const fetchSimilarLocations: QueryFunction<
	SimilarLocations,
	SimilarLocationsQueryKey
> = async ({ queryKey }) => {
	const { locationId } = queryKey[1]
	return await apiClient.getSimilarServicedOfficeLocations(locationId as string)
}

export const similarLocationsQuery: (
	locationId: string | undefined
) => UseQueryOptions<
	SimilarLocations,
	Error,
	SimilarLocations,
	SimilarLocationsQueryKey
> = (locationId) => {
	return {
		queryKey: ['similar_locations', { locationId }],
		queryFn: fetchSimilarLocations,
		staleTime: 15 * 60 * 1000, // 15 minutes
		enabled: !!locationId,
	}
}

type LocationQueryKey = ['location', { id: string | undefined }]

const fetchLocation: QueryFunction<LocationResult, LocationQueryKey> = async ({
	queryKey,
}) => {
	const { id } = queryKey[1]
	return await apiClient.getServicedOfficeLocationById(id as string)
}

type LocationByUrlQueryKey = ['location', { url: string }]

const fetchLocationByUrl: QueryFunction<
	LocationResult,
	LocationByUrlQueryKey
> = async ({ queryKey }) => {
	const { url } = queryKey[1]
	return await apiClient.getServicedOfficeLocationByUrl(url)
}

export const getLocationQuery: (
	id: string | undefined,
	client: QueryClient
) => UseQueryOptions<
	LocationResult,
	Error,
	LocationResult,
	LocationQueryKey
> = (id, client) => {
	return {
		queryKey: ['location', { id }],
		queryFn: fetchLocation,
		staleTime: 15 * 60 * 1000, // 15 minutes
		initialData: () =>
			client
				.getQueryData<LocationResult[]>('locations', { exact: true })
				?.find((l) => l.id === id),
		enabled: !!id,
	}
}

export const getLocationByUrlQuery: (
	url: string,
	client: QueryClient
) => UseQueryOptions<
	LocationResult,
	Error,
	LocationResult,
	LocationByUrlQueryKey
> = (url, client) => {
	return {
		queryKey: ['location', { url }],
		queryFn: fetchLocationByUrl,
		staleTime: 15 * 60 * 1000, // 15 minutes
		enabled: !!url,
		initialData: () =>
			client
				.getQueryData<LocationResult[]>('locations', { exact: true })
				?.find((l) => l.url === url),
		retryOnMount: false,
	}
}

type FloorQueryKey = ['floor', { floorId: string }]

const fetchFloor: QueryFunction<FloorResult, FloorQueryKey> = async ({
	queryKey,
}) => {
	const { floorId } = queryKey[1]
	return apiClient.getServicedOfficeFloorById(floorId)
}

export const getFloorQuery: (
	floorId: string
) => UseQueryOptions<FloorResult, Error, FloorResult, FloorQueryKey> = (
	floorId
) => {
	return {
		queryKey: ['floor', { floorId }],
		queryFn: fetchFloor,
		staleTime: 60 * 60 * 1000,
		enabled: floorId.length > 0,
	}
}

type FloorsQueryKey = ['floors', { floorIds: string[] }]

const fetchFloors: QueryFunction<FloorResult[], FloorsQueryKey> = async ({
	queryKey,
}) => {
	const { floorIds } = queryKey[1]
	return Promise.all(
		floorIds.map((floorId) => apiClient.getServicedOfficeFloorById(floorId))
	)
}

export const getFloorsQuery: (
	floorIds: string[]
) => UseQueryOptions<FloorResult[], Error, FloorResult[], FloorsQueryKey> = (
	floorIds
) => {
	return {
		queryKey: ['floors', { floorIds }],
		queryFn: fetchFloors,
		staleTime: 60 * 60 * 1000,
	}
}
