import { lazy } from 'react'
import type { FC } from 'react'
import type { RouteConfig } from '~/utils/routing'
import {
	getLocationByUrlQuery,
	similarLocationsQuery,
} from '~/queries/coworking_location'
import {
	getProviderQuery,
	getMembershipsQuery,
	getTestimonialsQuery,
	servicesQuery,
} from './queries'
import type { RouteComponentProps } from 'react-router-dom'
import type { StatusError } from '@alexanderathoodly/data-models'
import { useQuery, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { getNearbyAreasQuery } from '~/queries/coworking_search_result'

const LocationPageLayout = lazy(() => import('./layout'))
const NotFoundPageLayout = lazy(() => import('../notfound/layout'))

const LocationPage: FC<RouteComponentProps<{ url: string }>> = (props) => {
	const { i18n } = useTranslation()
	const queryClient = useQueryClient()
	const location = useQuery(
		getLocationByUrlQuery(props.match.params.url, queryClient)
	)
	if (
		location.status == 'error' &&
		(location.error as StatusError).statusCode == 404
	) {
		return <NotFoundPageLayout />
	}
	// We want to allow only '/kontorshotell/:url' and '/kontorshotell/:url/bilder'
	const pathnames = props.location.pathname.split('/')
	if (i18n.language !== 'sv') {
		if (pathnames.length >= 5 && pathnames[4] != 'bilder') {
			return <NotFoundPageLayout />
		}
	} else {
		if (pathnames.length >= 4 && pathnames[3] != 'bilder') {
			return <NotFoundPageLayout />
		}
	}

	return <LocationPageLayout {...props} />
}

const route: RouteConfig<{ url: string }> = {
	path: ['/kontorshotell/:url', '/en/serviced-offices/:url'],
	exact: false,
	component: LocationPage,
	prefetchQueries: async (match, _, queryClient) => {
		const l = await queryClient.fetchQuery(
			getLocationByUrlQuery(match.params.url, queryClient)
		)
		await Promise.all([
			queryClient.prefetchQuery(getProviderQuery(l.provider)),
			queryClient.prefetchQuery(getMembershipsQuery(l.id)),
			queryClient.prefetchQuery(getTestimonialsQuery(l.id)),
			queryClient.prefetchQuery(servicesQuery),
			queryClient.prefetchQuery(similarLocationsQuery(l.id)),
			queryClient.prefetchQuery(
				getNearbyAreasQuery(l.breadcrumbs.city.name.toLowerCase())
			),
		])
	},
}

export default route
