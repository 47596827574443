import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import { locizePlugin } from 'locize'
import Backend from 'i18next-locize-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

const options = {
	supportedLngs: ['en', 'sv'],
	preload: ['en', 'sv'],
	fallbackLng: 'sv',
	saveMissing: import.meta.env.MODE == 'development' ? true : false,
	ns: ['translation', 'meta_data', 'url'],
	defaultNS: 'translation',
	backend: {
		projectId: import.meta.env.VITE_LOCIZE_PROJECT_ID,
		apiKey: import.meta.env.VITE_LOCIZE_APIKEY,
		referenceLng: 'sv',
	},
	interpolation: {
		escapeValue: false,
	},
	react: {
		useSuspense: true,
		bindI18n: 'languageChanged editorSaved',
	},
	detection: {
		order: ['path'],
	},
}

void i18next
	.use(Backend)
	.use(initReactI18next)
	.use(locizePlugin)
	.use(LanguageDetector)
	.init(options)

export default i18next
