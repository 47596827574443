import { useCallback, useEffect } from 'react'
import type { FC } from 'react'
import { matchRoutes, renderRoutes } from 'react-router-config'
import { ChakraProvider } from '@chakra-ui/react'
import { chakraYtaTheme } from '@alexanderathoodly/ui-library'
import composeHead from '~/headmanager'
import Favicon from '#/icons/mstile-144x144.png'
import MaskIcon from '#/icons/safari-pinned-tab.svg'
import manifest from '#/icons/manifest.json?url'
import browserconfig from '#/icons/browserconfig.xml?url'
import type { HelmetProps } from 'react-helmet-async'
import type { RouteConfigComponentProps } from 'react-router-config'
import { useHistory, useLocation } from 'react-router-dom'

export function BaseHead(): HelmetProps {
	return {
		meta: [
			{
				name: 'viewport',
				content:
					'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0',
			},
			{ name: 'og:site_name', content: 'yta.se' },
			{ name: 'og:locale', content: 'sv_SE' },
			{ charSet: 'utf-8' },
			{ name: 'msapplication-config', content: browserconfig },
			{ name: 'theme-color', content: '#007AFF' },
		],
		link: [
			{
				rel: 'apple-touch-icon',

				href: Favicon,
			},
			{
				rel: 'icon',
				type: 'image/png',
				href: Favicon,
			},
			{ rel: 'manifest', href: manifest },
			{ rel: 'mask-icon', href: MaskIcon },
		],
	}
}

export const BaseComponent: FC<RouteConfigComponentProps> = ({ route }) => {
	const history = useHistory()
	const location = useLocation()
	const updateGA = useCallback((pathname: string, search: string) => {
		// Set the new page inside
		const searchParams = new URLSearchParams(search)
		const wsParam = searchParams.get('ws')
		if (wsParam) {
			ga('set', 'metric2', wsParam)
		}
		ga('set', 'page', pathname + search)
		ga('send', 'pageview')
	}, [])

	useEffect(() => {
		if (history.action != 'REPLACE') {
			updateGA(history.location.pathname, history.location.search)
		}
	}, [updateGA, history.action, history.location])
	if (!route) {
		return null
	}
	const branch = matchRoutes([route], location.pathname)

	return (
		<ChakraProvider theme={chakraYtaTheme as { [key: string]: string }}>
			{composeHead(branch)}
			{renderRoutes(route.routes)}
		</ChakraProvider>
	)
}
