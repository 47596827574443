import type { FC } from 'react'
import { I18nextProvider, useSSR } from 'react-i18next'
import type { I18nextProviderProps } from 'react-i18next'
import type { Resource } from 'i18next'

type I18ProviderProps = I18nextProviderProps & {
	i18nStore: Resource
	initialLanguage: string
}

const I18nProvider: FC<I18ProviderProps> = ({
	i18nStore,
	i18n,
	initialLanguage,
	children,
}) => {
	useSSR(i18nStore, initialLanguage)
	return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
}

export default I18nProvider
