import { useState, useMemo } from 'react';

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread2(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest();
}

function _toConsumableArray(arr) {
  return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread();
}

function _arrayWithoutHoles(arr) {
  if (Array.isArray(arr)) return _arrayLikeToArray(arr);
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

function _iterableToArray(iter) {
  if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}

function _iterableToArrayLimit(arr, i) {
  var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];

  if (_i == null) return;
  var _arr = [];
  var _n = true;
  var _d = false;

  var _s, _e;

  try {
    for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}

function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;

  for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i];

  return arr2;
}

function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}

function _createForOfIteratorHelper(o, allowArrayLike) {
  var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"];

  if (!it) {
    if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") {
      if (it) o = it;
      var i = 0;

      var F = function () {};

      return {
        s: F,
        n: function () {
          if (i >= o.length) return {
            done: true
          };
          return {
            done: false,
            value: o[i++]
          };
        },
        e: function (e) {
          throw e;
        },
        f: F
      };
    }

    throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
  }

  var normalCompletion = true,
      didErr = false,
      err;
  return {
    s: function () {
      it = it.call(o);
    },
    n: function () {
      var step = it.next();
      normalCompletion = step.done;
      return step;
    },
    e: function (e) {
      didErr = true;
      err = e;
    },
    f: function () {
      try {
        if (!normalCompletion && it.return != null) it.return();
      } finally {
        if (didErr) throw err;
      }
    }
  };
}

var entries = Object.entries; // Required because of https://github.com/microsoft/TypeScript/issues/21826

function ObjectMap(input, mapFn) {
  return entries(input).reduce(function (p, _ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        k = _ref2[0],
        v = _ref2[1];

    return Object.assign(p, _defineProperty({}, k, mapFn(v, k)));
  }, {});
}

function identity(a) {
  return a;
}

// polyfill from
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/isInteger
// to support IE
Number.isInteger = Number.isInteger || function (value) {
  return typeof value === "number" && isFinite(value) && Math.floor(value) === value;
};

function isEmail(value) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? "" : "Ogiltig e-postadress";
}

function isDate(value) {
  // We only accepts dates as YYYY-MM-DD
  return /\d{4}-\d{2}-\d{2}/.test(value) ? "" : "Ogiltigt datum";
}

function isPhoneNumber(value) {
  return /^\+?[\d -]{8,}$/.test(value) ? "" : "Ogiltigt telefonnummer";
}

function isNumber(value) {
  return value !== null && isNaN(value) ? "Inte ett nummer" : "";
}

function isInteger(value) {
  return value !== null && Number.isInteger(value) ? "" : "Inte en siffra";
}

var _excluded = ["validators"];
/**
 * Errors are represented as strings currently, which is an error message
 */

/**
 *
 * Generic functions that touch fields
 *
 */
function isValidValue(f, value) {
  try {
    var _data = f.fromForm(value);

    var errors = f.validators.reduce(function (p, validator) {
      return [].concat(_toConsumableArray(p), [validator(_data)]);
    }, []).filter(function (v) {
      return v !== "";
    });
    return errors;
  } catch (e) {
    return ["Invalid type"];
  }
}
/**
 * Wraps a valdiator to accept empty values in addition to the ones traditionnaly accepted
 * Use it in fields that are not required so that the regular validators can work on non empty values
 */


function wrapNotRequired(validator) {
  for (var _len = arguments.length, emptyValues = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    emptyValues[_key - 1] = arguments[_key];
  }

  return function (v) {
    if (emptyValues.indexOf(v) > -1) {
      return "";
    }

    return validator(v);
  };
}
/**
 *
 * Fields definitions
 *
 */


function StringField() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      required = _ref.required,
      validators = _ref.validators,
      defaultValue = _ref.defaultValue;

  validators = validators || [];

  if (required) {
    required && validators.push(function (v) {
      return v === "" ? "Fältet måste fyllas i" : "";
    });
  } else {
    validators = validators.map(function (validator) {
      return wrapNotRequired(validator, "");
    });
  }

  return {
    validators: validators,
    defaultValue: defaultValue || "",
    toForm: identity,
    fromForm: identity
  };
}

function NumberField() {
  var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      required = _ref2.required,
      validators = _ref2.validators,
      defaultValue = _ref2.defaultValue,
      minValue = _ref2.minValue,
      maxValue = _ref2.maxValue;

  validators = validators || [];
  validators.push(isNumber);

  if (required) {
    validators.push(function (v) {
      return v === null ? "Fältet måste fyllas i" : "";
    });
  }

  if (minValue !== undefined) {
    validators.push(function (v) {
      return v && v < minValue ? "M\xE5ste vara h\xF6gre \xE4n ".concat(minValue) : "";
    });
  }

  if (maxValue !== undefined) {
    validators.push(function (v) {
      return v && v > maxValue ? "M\xE5ste vara l\xE4gre \xE4n ".concat(maxValue) : "";
    });
  }

  var numberFormat = new Intl.NumberFormat("sv-SE");

  var toForm = function toForm(v) {
    return v === null ? "" : numberFormat.format(v);
  };

  var fromForm = function fromForm(v) {
    var cleanValue = v.replace(/\s/g, "").replace(",", ".");
    return cleanValue ? Number(cleanValue) : null;
  };

  return {
    validators: validators,
    defaultValue: defaultValue || null,
    toForm: toForm,
    fromForm: fromForm
  };
}

function IntegerField() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

  var _props$validators = props.validators,
      validators = _props$validators === void 0 ? [] : _props$validators,
      rest = _objectWithoutProperties(props, _excluded);

  return NumberField(_objectSpread2(_objectSpread2({}, rest), {}, {
    validators: validators.concat(isInteger)
  }));
}

function MappingField(mapping, _ref3) {
  var defaultValue = _ref3.defaultValue,
      validators = _ref3.validators;

  function toForm(v) {
    var asString = JSON.stringify(v);
    var entry = entries(mapping).find(function (_ref4) {
      var _ref5 = _slicedToArray(_ref4, 2),
          _ = _ref5[0],
          internalValue = _ref5[1];

      return JSON.stringify(internalValue) == asString;
    });

    if (!entry) {
      throw "No value found in the mapping for " + asString;
    }

    return entry[0];
  }

  return {
    validators: validators || [],
    defaultValue: defaultValue,
    toForm: toForm,
    fromForm: function fromForm(v) {
      return mapping[v];
    }
  };
}

function EmailField() {
  var _ref6 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      required = _ref6.required,
      defaultValue = _ref6.defaultValue,
      _ref6$validators = _ref6.validators,
      validators = _ref6$validators === void 0 ? [] : _ref6$validators;

  return StringField({
    required: required || false,
    defaultValue: defaultValue,
    validators: [isEmail].concat(_toConsumableArray(validators))
  });
}

function DateField() {
  var _ref7 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      required = _ref7.required,
      defaultValue = _ref7.defaultValue,
      _ref7$validators = _ref7.validators,
      validators = _ref7$validators === void 0 ? [] : _ref7$validators;

  return StringField({
    required: required || false,
    defaultValue: defaultValue,
    validators: [isDate].concat(_toConsumableArray(validators))
  });
}

function PhoneNumberField() {
  var _ref8 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      required = _ref8.required,
      defaultValue = _ref8.defaultValue;

  return StringField({
    required: required || false,
    defaultValue: defaultValue,
    validators: [isPhoneNumber]
  });
}

function ListField(baseField) {
  var _ref9 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
      minLength = _ref9.minLength,
      maxLength = _ref9.maxLength,
      defaultValue = _ref9.defaultValue;

  // Each original validator is wrapped so that it will run on each value of the array until one reaches an error
  var validators = baseField.validators.map(function (validator) {
    return function (values) {
      var _iterator = _createForOfIteratorHelper(values),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var _value = _step.value;
          var potentialError = validator(_value);

          if (potentialError) {
            return potentialError;
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      return "";
    };
  });
  minLength && validators.push(function (v) {
    return v.length < minLength ? "Enter at least ".concat(minLength, " values") : "";
  });
  maxLength && validators.push(function (v) {
    return v.length > maxLength ? "Enter at most ".concat(minLength, " values") : "";
  });

  function toForm(data) {
    return data.map(function (v) {
      return baseField.toForm(v);
    });
  }

  function fromForm(data) {
    return data.map(function (v) {
      return baseField.fromForm(v);
    });
  }

  return {
    validators: validators,
    toForm: toForm,
    fromForm: fromForm,
    defaultValue: defaultValue || []
  };
}
/**
 * Compared to other fields, the boolean field cannot automatically form a value accepted by a HTML
 * property. This is because there are many ways to represent such an input and they all have weird value settings.
 * For instance a `checkbox` input has a value equal to `unchecked` or to its `value` property (or `on`).
 * Therefore, the responsability to convert into a valid HTML property is delayed in this case to the frontend
 * library.
 */


function BooleanField() {
  var _ref10 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      defaultValue = _ref10.defaultValue;

  return {
    validators: [],
    toForm: identity,
    fromForm: identity,
    defaultValue: defaultValue || false
  };
}

/**
 * Runs all the validators and returns a list of errors
 * if everything is fine, you'd get an empty array
 */
function getInitialState(fields) {
  return {
    values: ObjectMap(fields, function (f) {
      return f.toForm(f.defaultValue);
    }),
    errors: ObjectMap(fields, function (f) {
      return isValidValue(f, f.toForm(f.defaultValue));
    }),
    pristineStatus: ObjectMap(fields, function () {
      return true;
    }),
    nonFieldErrors: [],
    triedSubmit: false
  };
}
/**
 * This hook saves the values and errors for all the fields of a form
 * it also handles serializing and deserializing the values from the JS value
 * into the form values (strings)
 */


function useForm(_ref) {
  var fields = _ref.fields,
      mapping = _ref.mapping,
      onFormChange = _ref.onFormChange,
      onFormSubmit = _ref.onFormSubmit;
  var mapFields = mapping || {
    toForm: identity,
    fromForm: identity
  };

  var _useState = useState(function () {
    return getInitialState(fields);
  }),
      _useState2 = _slicedToArray(_useState, 2),
      state = _useState2[0],
      setState = _useState2[1];

  function getNonFieldErrors() {
    return state.nonFieldErrors;
  }

  function getValue(fieldname) {
    return state.values[fieldname];
  }

  function getErrors(fieldname) {
    return state.errors[fieldname];
  }

  function isPristine(fieldname) {
    return state.pristineStatus[fieldname];
  }

  function setValue(fieldname, newValue) {
    setState(function (prevState) {
      var newErrors = isValidValue(fields[fieldname], newValue);

      var newValues = _objectSpread2(_objectSpread2({}, prevState.values), {}, _defineProperty({}, fieldname, newValue));

      onFormChange && onFormChange(getFormData(newValues));
      return _objectSpread2(_objectSpread2({}, prevState), {}, {
        values: newValues,
        errors: _objectSpread2(_objectSpread2({}, prevState.errors), {}, _defineProperty({}, fieldname, newErrors)),
        pristineStatus: _objectSpread2(_objectSpread2({}, prevState.pristineStatus), {}, _defineProperty({}, fieldname, false))
      });
    });
  }

  function isFormValid() {
    return Object.values(state.errors).findIndex(function (a) {
      return a.length > 0;
    }) === -1;
  }

  function getFormData(values) {
    var validatedData = ObjectMap(fields, function (field, fieldname) {
      return field.fromForm(values[fieldname]);
    });
    return mapFields.fromForm(validatedData);
  }

  function trySubmit() {
    setTriedSubmit(true);

    if (isFormValid()) {
      onFormSubmit && onFormSubmit(getFormData(state.values));
    }
  }

  function setFormData(newData) {
    setState(function (prevState) {
      var validatedData = mapFields.toForm(newData);
      var newValues = ObjectMap(fields, function (field, fieldname) {
        return field.toForm(validatedData[fieldname]);
      });
      return _objectSpread2(_objectSpread2({}, prevState), {}, {
        values: newValues,
        errors: ObjectMap(fields, function (field, fieldname) {
          return isValidValue(field, newValues[fieldname]);
        }),
        pristineStatus: ObjectMap(fields, function () {
          return true;
        })
      });
    });
  }
  /**
   * Set errors outside of the regular validation
   * This can be used to set errors received by an API request for instance
   * Note that this will override the errors as validated by the form
   */


  function setErrors(errors) {
    setState(function (prevState) {
      return _objectSpread2(_objectSpread2({}, prevState), {}, {
        errors: _objectSpread2(_objectSpread2({}, prevState.errors), errors)
      });
    });
  }
  /**
   * Set nonField levels errors from outside the regular validation
   * This can be used to set errors received by an API request, such as
   * a duplicate record, or a network error as well as other unexpected problems
   */


  function setNonFieldErrors(errors) {
    setState(function (prevState) {
      return _objectSpread2(_objectSpread2({}, prevState), {}, {
        nonFieldErrors: errors
      });
    });
  }

  function setTriedSubmit(triedSubmit) {
    setState(function (prevState) {
      return _objectSpread2(_objectSpread2({}, prevState), {}, {
        triedSubmit: triedSubmit
      });
    });
  }

  function getTriedSubmit() {
    return state.triedSubmit;
  }

  return {
    getValue: getValue,
    getErrors: getErrors,
    getNonFieldErrors: getNonFieldErrors,
    setValue: setValue,
    setFormData: setFormData,
    setErrors: setErrors,
    setNonFieldErrors: setNonFieldErrors,
    state: state,
    isPristine: isPristine,
    isFormValid: isFormValid,
    trySubmit: trySubmit,
    getTriedSubmit: getTriedSubmit
  };
}

function computeStepValidity(steps, formState) {
  var stepValidity = {}; // If a step is invalid, all the subsequence steps are invalid as well
  // So we start with true and as soon as it's false all the next steps will be false

  var isValid = true;

  var _iterator = _createForOfIteratorHelper(steps),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var step = _step.value;

      // The step is invalid if at least one of the field contains an error
      var _iterator2 = _createForOfIteratorHelper(step.fieldnames),
          _step2;

      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var fieldname = _step2.value;

          if (formState.errors[fieldname].length > 0) {
            isValid = false;
          }
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }

      stepValidity[step.name] = isValid;
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }

  return stepValidity;
}

function useSteps(steps, formState) {
  var stepValidity = useMemo(function () {
    return computeStepValidity(steps, formState);
  }, [steps, formState.errors]);
  var initalState = stepValidity;

  var _useState = useState(initalState),
      _useState2 = _slicedToArray(_useState, 2),
      state = _useState2[0],
      setState = _useState2[1];

  function submitStep(stepName) {
    if (stepValidity[stepName]) {
      setState(_objectSpread2(_objectSpread2({}, state), {}, _defineProperty({}, stepName, true)));
      return true;
    }

    return false;
  }

  function isStepValid(stepName) {
    if (stepName in stepValidity) {
      return stepValidity[stepName];
    }

    throw new Error("Unknown fieldname ".concat(stepName));
  }

  function isStepSubmitted(stepName) {
    if (stepName in state) {
      return state[stepName];
    }

    throw new Error("Unknown fieldname ".concat(stepName));
  }

  function isStepVisible(stepName) {
    // The visible steps are all the steps until the first one that is not submitted
    var visible = true;

    var _iterator3 = _createForOfIteratorHelper(steps),
        _step3;

    try {
      for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
        var step = _step3.value;

        if (step.name == stepName) {
          return visible;
        }

        if (!isStepSubmitted(step.name)) {
          visible = false;
        }
      }
    } catch (err) {
      _iterator3.e(err);
    } finally {
      _iterator3.f();
    }

    return visible;
  }

  return {
    submitStep: submitStep,
    isStepValid: isStepValid,
    isStepSubmitted: isStepSubmitted,
    isStepVisible: isStepVisible
  };
}

export { BooleanField, DateField, EmailField, IntegerField, ListField, MappingField, NumberField, PhoneNumberField, StringField, useForm, useSteps };
