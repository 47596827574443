import {
	FC,
	JSXElementConstructor,
	PropsWithChildren,
	ReactElement,
	useCallback,
	useMemo,
} from 'react'
import {
	Box,
	Drawer,
	DrawerBody,
	DrawerHeader,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
	useDisclosure,
	IconButton,
	Link as ChakraLink,
	Divider,
	MenuList,
	Menu,
	MenuButton,
	Button,
	MenuItem,
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionIcon,
	AccordionPanel,
	Text,
	Select,
} from '@chakra-ui/react'
import type { ChakraProps } from '@chakra-ui/react'
import {
	NavigationMenuIcn,
	IcnYtaLogo,
	SearchIcn,
	MenuArrow,
} from '@alexanderathoodly/ui-library'
import { UserMenu } from './usermenu'
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { GAAction, sendGA4Event } from '~/analytics/ga'
import { useTranslation } from 'react-i18next'
import { useLinks } from './uselinks'

const SubMenuPopover: FC<PropsWithChildren> = ({ children }) => {
	return (
		<MenuList
			boxShadow="0px 6px 15px var(--chakra-colors-blackAlpha-400)"
			borderColor="white"
			borderRadius="6px"
			py={4}
			px={2}
			zIndex={3}
		>
			{children}
		</MenuList>
	)
}

interface SubMenuItemProps {
	link: string
	title: string
	subTitle: string
	icon: ReactElement<any, string | JSXElementConstructor<any>>
}

const SubMenuItem: FC<SubMenuItemProps> = ({ link, title, subTitle, icon }) => {
	return (
		<MenuItem
			as={Link}
			to={link}
			icon={icon}
			pr={10}
			_focus={{ bgColor: 'white', textDecoration: 'underline' }}
		>
			<Text fontWeight="semibold" fontSize="md">
				{title}
			</Text>
			<Text color="gray.500">{subTitle}</Text>
		</MenuItem>
	)
}

interface HeaderProps {
	ytaIconColor?: string
	textColor?: 'black' | 'white'
}

export const Header: FC<HeaderProps & ChakraProps> = ({
	ytaIconColor = 'black',
	textColor = 'gray.800',
	...rest
}) => {
	const { t, i18n } = useTranslation()
	const currentLanguage = i18n.language
	const { pathname } = useLocation()
	const match = useRouteMatch()
	const cleanedMatchPath =
		match.path[match.path.length - 1] == '*'
			? match.path.substring(0, match.path.length - 1)
			: match.path // For /kontors
	const { isOpen, onOpen, onClose } = useDisclosure()
	const history = useHistory()
	const handleLanguageChange = useCallback<
		React.ChangeEventHandler<HTMLSelectElement>
	>(
		async (e) => {
			const nextLanguage = e.target.value
			await i18n.changeLanguage(nextLanguage)
			const currentLanguageTranslations = i18n.getResourceBundle(
				currentLanguage,
				'url'
			) as { [key: string]: string } // type cast because getResourceBundle() returns any
			const nextLanguageTranslations = i18n.getResourceBundle(
				nextLanguage,
				'url'
			) as { [key: string]: string } // type cast because getResourceBundle() returns any
			if (currentLanguageTranslations && nextLanguageTranslations) {
				const key = Object.keys(currentLanguageTranslations)
					.reverse()
					.find((key) => {
						const value = currentLanguageTranslations[key]
						if (!value) {
							return false
						}
						return cleanedMatchPath == value
					})
				if (key) {
					const formattedKey = key
						.split('/')
						.filter((c) => !c.includes(':'))
						.join('/')
					const currentLanguageUrl = currentLanguageTranslations[formattedKey]!
					const startIndex =
						pathname.indexOf(currentLanguageUrl) + currentLanguageUrl.length
					const restOfUrl = pathname.substring(startIndex) // To extract, for example, /stockholm or /locationId
					const url = nextLanguageTranslations[formattedKey]! + restOfUrl
					document.documentElement.lang = nextLanguage
					history.push(`${url[0] == '/' ? url : `/${url}`}`) // To make sure we have slash
				}
			}
			// We do not change URL which do not exist in Locize
		},
		[i18n, pathname, history, cleanedMatchPath, currentLanguage]
	)
	const pageType = useMemo(() => {
		const mainPath = pathname.split('/')[1]
		return mainPath == ''
			? 'home_page'
			: mainPath == 'kontorshotell'
			? 'location'
			: mainPath == 'my_account'
			? 'my_account'
			: mainPath == 'content'
			? 'content'
			: 'serp'
	}, [pathname])
	const links = useLinks()

	return (
		<Box
			h={16}
			mb={{ base: 5, md: 7, lg: 10 }}
			px={{ base: 5, lg: 7 }}
			borderBottom="1px"
			borderColor="grey.100"
			bg="white"
			display="flex"
			alignItems="center"
			{...rest}
		>
			{/* Mobile menu */}
			<IconButton
				icon={
					<NavigationMenuIcn
						color={textColor == 'white' ? textColor : 'blackAlpha.800'}
					/>
				}
				aria-label="menu"
				onClick={onOpen}
				h={10}
				w={10}
				mr={4}
				minW={0}
				display={{ lg: 'none' }}
				variant="link"
			>
				{t('Meny')}
			</IconButton>
			<Drawer onClose={onClose} isOpen={isOpen} placement="left">
				<DrawerOverlay></DrawerOverlay>
				<DrawerContent>
					<DrawerCloseButton />
					<DrawerHeader>{t('Meny')}</DrawerHeader>
					<DrawerBody display="flex" flexDirection="column" gap="40px">
						{links.heroLinks.map((link) => (
							<ChakraLink
								key={link.link}
								variant="black"
								fontWeight={500}
								fontSize="md"
								href={link.link}
							>
								{t(link.title)}
							</ChakraLink>
						))}
						<Accordion allowToggle>
							<AccordionItem border="none">
								<AccordionButton py={2} px={0} color="gray.600">
									{t('Jämför kontorshotell')}
									<AccordionIcon />
								</AccordionButton>
								<AccordionPanel
									display="flex"
									flexDirection="column"
									gap={5}
									mt={4}
								>
									{links.cityLinks.map((city) => (
										<ChakraLink
											key={city.title}
											variant="black"
											fontWeight={500}
											fontSize="md"
											href={city.link}
										>
											{city.title}
										</ChakraLink>
									))}
								</AccordionPanel>
							</AccordionItem>
						</Accordion>
						<Select
							display={{ base: 'inline-block', md: 'none' }}
							backgroundColor="white"
							placeholder={t('Språk') || 'Language'}
							onChange={handleLanguageChange}
							value={currentLanguage}
						>
							<option value="en">English</option>
							<option value="sv">Svenska</option>
						</Select>
					</DrawerBody>
				</DrawerContent>
			</Drawer>
			<ChakraLink as={Link} to={links.homePageLink}>
				<IcnYtaLogo color={ytaIconColor} />
			</ChakraLink>
			<Divider
				orientation="vertical"
				mx={8}
				h={5}
				display={{ base: 'none', lg: 'block' }}
				borderColor={textColor}
			/>
			{/* Desktop menu */}
			<Box display={{ base: 'none', lg: 'flex' }} gap="20px">
				{links.heroLinks.map((link) => (
					<ChakraLink
						key={link.link}
						variant={textColor}
						fontWeight={500}
						fontSize="md"
						href={link.link}
					>
						{t(link.title)}
					</ChakraLink>
				))}
				<Menu placement="bottom" offset={[0, 15]}>
					<MenuButton
						as={Button}
						variant="link"
						color={textColor}
						_hover={{
							textDecoration: textColor == 'white' ? 'none' : 'underline',
							color: textColor == 'white' ? 'gray.100' : textColor,
						}}
						_active={{
							textDecoration: 'underline',
							color: textColor == 'white' ? 'gray.100' : textColor,
						}}
					>
						{t('Jämför kontorshotell')}
					</MenuButton>
					<SubMenuPopover>
						<MenuArrow />
						{links.cityLinks.map((city) => (
							<SubMenuItem
								key={city.link}
								link={city.link}
								icon={<SearchIcn w={4} mt="-1rem" strokeWidth={2} />}
								title={city.title}
								subTitle={t('Sök bland hundratals kontor i {{city}}', {
									city: city.title,
								})}
							/>
						))}
					</SubMenuPopover>
				</Menu>
			</Box>
			<Box ml="auto" display="flex" gap={{ base: 4, md: 10 }}>
				<Box display={{ base: 'none', md: 'inline-block' }}>
					<Select
						backgroundColor="white"
						placeholder={t('Språk') || 'Language'}
						onChange={handleLanguageChange}
						value={i18n.language}
					>
						<option value="en">English</option>
						<option value="sv">Svenska</option>
					</Select>
				</Box>
				<Button
					as={Link}
					to={t('/kontorshjalpen', { ns: 'url' })}
					size={{ base: 'sm', md: 'md' }}
					alignSelf="center"
					fontWeight="normal"
					onClick={() => {
						GAAction({
							category: 'engagement',
							action: 'click_lead_button',
							label:
								textColor == 'white' ? 'homepage-header' : 'marketplace-header',
						})
						sendGA4Event('click_kontorshjalpen', {
							page_type: pageType,
							placement: 'header',
							placement_type: 'button',
							content: 'Become matchad',
						})
					}}
				>
					{t('Bli matchad')}
				</Button>
				<UserMenu textColor={textColor} />
			</Box>
		</Box>
	)
}
