export const MAX_SERP_SIZE = 2500
export const MIN_AREA_PER_PERSON = 7
export const MAX_AREA_PER_PERSON = 17
export const MIN_SERP_SIZE = 0

export const LAT_MARGIN = 0.1
export const LON_MARGIN = 0.25

export const DEFAULT_PHONE_NUMBER = '08-520 277 91'
export const DEFAULT_PHONE_NUMBER_INTERNATIONAL = '+46 (0)8-520 277 91'
export const PHONE_NUMBER_LINK = 'tel:+46852027791'
export const CALENDLY_LINK =
	'https://calendly.com/yta-se/introduction-call?hide_gdpr_banner=1'

export const CALENDLY_COLLABORATION_LINK =
	'https://calendly.com/tim-542/hyr-ut-era-lokaler-med-yta-se?hide_gdpr_banner=1'

export const CALENDLY_ENTERPRISE_LINK =
	'https://calendly.com/yta-se/enterprise-office-solutions'

export const CURRENT_ADDRESS = {
	city: 'Stockholm',
	postcode: '118 46',
	street: 'Götgatan 22A',
}
