import { Component } from 'react'
import type { ReactNode } from 'react'
import { ErrorPage, LazyModuleErrorPage } from './layouts/pages'
import { ChakraProvider } from '@chakra-ui/react'
import { chakraYtaTheme } from '@alexanderathoodly/ui-library'
import type StackdriverErrorReporter from 'stackdriver-errors-js'

interface Props {
	children?: ReactNode
	errorHandler: StackdriverErrorReporter
}

interface State {
	error: Error | null
}

export default class ErrorBoundary extends Component<Props, State> {
	state: State = { error: null }

	static getDerivedStateFromError(error: Error): { error: Error } {
		return { error: error }
	}

	componentDidCatch(error: Error): void {
		if (error) {
			console.error(error)
			void this.props.errorHandler.report(error)
		}
	}

	render(): ReactNode {
		if (this.state.error) {
			return (
				<ChakraProvider theme={chakraYtaTheme as { [key: string]: string }}>
					{this.state.error.message.includes('dynamically imported module') ? (
						<LazyModuleErrorPage />
					) : (
						<ErrorPage error={this.state.error} />
					)}
				</ChakraProvider>
			)
		}
		return this.props.children
	}
}
