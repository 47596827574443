import type { QueryFunction, UseQueryOptions } from 'react-query'
import type {
	ProviderResult,
	ServiceOfficeMembershipResult,
	ServiceOfficeTestimonialResult,
	ServiceOfficeServiceResult,
	RoomResult,
} from '@alexanderathoodly/data-models'
import { apiClient } from '~/utils/requests'
import type { AuthQueryFactory } from '~/queries/useauthquery'

type ProviderQueryKey = ['provider', { id: string | undefined }]

const fetchProvider: QueryFunction<ProviderResult, ProviderQueryKey> = async ({
	queryKey,
}) => {
	const { id } = queryKey[1]
	return await apiClient.getServicedOfficeProviderById(id as string) // casting is fine as `enabled` prevents the id to be undefined
}

export const getProviderQuery: (
	id: string | undefined
) => UseQueryOptions<
	ProviderResult,
	Error,
	ProviderResult,
	ProviderQueryKey
> = (id) => {
	return {
		queryKey: ['provider', { id }],
		queryFn: fetchProvider,
		staleTime: 60000,
		enabled: !!id,
	}
}

type MembershipQueryKey = ['memberships', { location: string | undefined }]

const fetchMemberships: QueryFunction<
	ServiceOfficeMembershipResult[],
	MembershipQueryKey
> = async ({ queryKey }) => {
	return await apiClient.getServicedOfficeMemberships({
		location: queryKey[1].location as string, // casting is fine as `enabled` prevents the id to be undefined
	})
}

export const getMembershipsQuery: (
	locationId: string | undefined
) => UseQueryOptions<
	ServiceOfficeMembershipResult[],
	Error,
	ServiceOfficeMembershipResult[],
	MembershipQueryKey
> = (locationId) => {
	return {
		queryKey: ['memberships', { location: locationId }],
		queryFn: fetchMemberships,
		staleTime: 60000,
		enabled: !!locationId,
	}
}

type AuthMembershipQueryKey = [
	'memberships',
	'auth',
	{ location: string | undefined }
]

export const getAuthMembershipsQuery: AuthQueryFactory<
	[locationId: string | undefined],
	ServiceOfficeMembershipResult[],
	ServiceOfficeMembershipResult[],
	AuthMembershipQueryKey
> = (_, locationId) => {
	return {
		queryKey: ['memberships', 'auth', { location: locationId }],
		queryFn: async ({ jwtToken, queryKey }) => {
			const locationId = queryKey[2].location
			if (!locationId) {
				return []
			}
			return await apiClient.getAuthServicedOfficeMemberships(
				{ location: locationId },
				jwtToken
			)
		},
		staleTime: 60000,
		enabled: !!locationId,
	}
}

type TestimonialsQueryKey = ['testimonials', { location: string | undefined }]

const fetchTestimonials: QueryFunction<
	ServiceOfficeTestimonialResult[],
	TestimonialsQueryKey
> = async ({ queryKey }) => {
	return await apiClient.getServicedOfficeTestimonials({
		location: queryKey[1].location as string, // casting is fine as `enabled` prevents the id to be undefined
	})
}

export const getTestimonialsQuery: (
	locationId: string | undefined
) => UseQueryOptions<
	ServiceOfficeTestimonialResult[],
	Error,
	ServiceOfficeTestimonialResult[],
	TestimonialsQueryKey
> = (locationId) => {
	return {
		queryKey: ['testimonials', { location: locationId }],
		queryFn: fetchTestimonials,
		staleTime: 60000,
		enabled: !!locationId,
	}
}

type ServicesQueryKey = ['coworking_services']

const fetchServices: QueryFunction<
	ServiceOfficeServiceResult[],
	ServicesQueryKey
> = async () => {
	return await apiClient.getServicedOfficeServices()
}

export const servicesQuery: UseQueryOptions<
	ServiceOfficeServiceResult[],
	Error,
	ServiceOfficeServiceResult[],
	ServicesQueryKey
> = {
	queryFn: fetchServices,
	queryKey: ['coworking_services'],
	staleTime: 60 * 60 * 1000,
}

type RoomQueryKey = ['room', { roomId: string }]

const fetchRoom: QueryFunction<RoomResult, RoomQueryKey> = async ({
	queryKey,
}) => {
	const { roomId } = queryKey[1]
	return await apiClient.getServicedOfficeRoomById(roomId)
}

export const getRoomQuery: (
	roomId: string
) => UseQueryOptions<RoomResult, Error, RoomResult, RoomQueryKey> = (
	roomId
) => {
	return {
		queryKey: ['room', { roomId }],
		queryFn: fetchRoom,
		staleTime: 60 * 60 * 1000,
		enabled: roomId != '',
	}
}

export const getRoomQueries = (
	roomIds: string[],
	isShown: boolean
): UseQueryOptions<RoomResult, Error, RoomResult, RoomQueryKey>[] =>
	roomIds.map((roomId) => {
		return {
			queryKey: ['room', { roomId }],
			queryFn: fetchRoom,
			staleTime: 60 * 60 * 1000,
			retry: false,
			enabled: isShown,
		}
	})
