/**
 * Sets the ad features On/Off depending on user consent. This is run in a closure so that the consent parameter is calculated
 * after consent is given instead of when the page load when the consent would always be false
 */
function setGaAdFeature() {
	const acceptedMarketing = window.Cookiebot?.consent?.marketing === true
	ga('set', 'allowAdFeatures', acceptedMarketing)
}

const initGAScript = () => {
	ga('create', 'UA-55337025-1', { allowAnchor: true })
	ga(setGaAdFeature)

	/* Adds an event when the user is about to leave the page : baby don't go */
	window.addEventListener('beforeunload', () => {
		ga('send', 'event', 'Departure', 'click', { transport: 'beacon' })
	})
	// If the consent changes, set the GA ad features accordingly
	window.addEventListener('CookiebotOnConsentReady', setGaAdFeature)

	// If the user change its consent (first accepts and then refuse, or the opposite), we will reload the page
	// this is done to avoid troubles like GA still sending events if you withdraw your consent
	// We could instead try to patch again GA and FBQ but there are many ways to get hurt by it so right now
	// we will simply reload the page which makes it easier for Cookiebot to block the analytics scripts again
	let timesConsented = 0
	window.addEventListener('CookiebotOnConsentReady', function () {
		timesConsented += 1
		if (timesConsented > 1) {
			window.location.reload()
		}
	})
}

function initializeAnalytics(): void {
	initGAScript()
}
export { initializeAnalytics }
