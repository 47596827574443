import { Box, ChakraComponent } from '@chakra-ui/react'
import { useAuth } from 'reactfire'
import { useQueryClient } from 'react-query'

export const SignOutButton: ChakraComponent<'button'> = ({
	children,
	...props
}) => {
	const auth = useAuth()
	const queryClient = useQueryClient()
	return (
		<Box
			onClick={() => {
				queryClient.removeQueries(['showing_requests'])
				queryClient.removeQueries(['shortlist', 'current'])
				void auth.signOut()
			}}
			{...props}
		>
			{children}
		</Box>
	)
}
