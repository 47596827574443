import { lazy } from 'react'
import type { FC } from 'react'
import type { RouteConfig } from '~/utils/routing'
import { useYtaQuery, providerById } from '@alexanderathoodly/data-models'
import type { RouteComponentProps } from 'react-router-dom'
import type { StatusError } from '@alexanderathoodly/data-models'
import { getProviderQuery, getTestimonialsQuery } from './queries'

const OperatorPageLayout = lazy(() => import('./layout'))
const NotFoundPageLayout = lazy(() => import('../notfound/layout'))

const OperatorPage: FC<RouteComponentProps<{ id: string }>> = (props) => {
	const providerQuery = useYtaQuery(providerById({ id: props.match.params.id }))
	if (
		(providerQuery.status == 'error' &&
			(providerQuery.error as StatusError).statusCode == 404) ||
		(providerQuery.data && !providerQuery.data.is_operator_page_active)
	) {
		return <NotFoundPageLayout />
	}

	return <OperatorPageLayout {...props} />
}

const route: RouteConfig<{ id: string }> = {
	path: ['/operatörer/:id', '/en/operators/:id'],
	exact: false,
	component: OperatorPage,
	prefetchQueries: async (match, _, queryClient) => {
		const provider = await queryClient.fetchQuery(
			getProviderQuery(match.params.id)
		)
		await Promise.all([
			getTestimonialsQuery(provider.locations.map((l) => l.id)),
		])
	},
}

export default route
