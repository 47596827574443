import type { HelmetProps } from 'react-helmet-async'

const ld_json = {
	'@context': 'http://schema.org',
	'@type': 'WebSite',
	url: 'https://yta.se/kontorshjalpen',
}

export default function OfficeHelpHead(): HelmetProps {
	return {
		meta: [
			{
				name: 'google-site-verification',
				content: 'kGoVs99UOiWK-JxhcWp5PPFRW8hw5iurplR_O2Vdtlg',
			},
		],
		script: [
			{ type: 'application/ld+json', innerHTML: JSON.stringify(ld_json) },
			{
				src: '//js.hs-scripts.com/3285375.js',
				defer: true,
				async: true,
				id: 'hs-script-loader',
			},
		],
		style: [
			{
				type: 'text/css',
				innerHTML:
					'html {margin: 0; height: 100 %; overflow: hidden;} iframe { position: absolute; left: 0; right: 0; bottom: 0; top: 0; border: 0; }',
			},
		],
	}
}
