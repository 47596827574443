import type {
	DetailedShortlistResult,
	PropositionResult,
} from '@alexanderathoodly/data-models'
import { apiClient } from '~/utils/requests'
import type { AuthQueryFactory } from './useauthquery'

type CurrentShortlistQueryKey = ['shortlist', 'current']

/**
 * Javascript cannot really work with sets of set, so if we want to compare 2 lists of rooms
 * Our best shot is to hash each list into a stable string to make it into a single comparison
 * As such we join all the IDs after sorting them to ensure stability.
 * UUIDs only use hexadecimal characters and `-` so joining with `__` is also safe
 */
export function hashRoomList(ids: Array<string>): string {
	return ids.slice().sort().join('__')
}

export const currentShortlistQuery: AuthQueryFactory<
	[],
	DetailedShortlistResult,
	DetailedShortlistResult,
	CurrentShortlistQueryKey
> = () => {
	return {
		queryKey: ['shortlist', 'current'],
		queryFn: async ({ jwtToken }) => {
			return await apiClient.getCurrentShortlist(jwtToken)
		},
		staleTime: 15 * 60 * 1000, // Valid 15 minutes
	}
}

type ShortlistByUrlQueryKey = ['shortlist', { url: string }]

export const shortlistByUrlQuery: AuthQueryFactory<
	[url: string],
	DetailedShortlistResult,
	DetailedShortlistResult,
	ShortlistByUrlQueryKey
> = (_, url) => {
	return {
		queryKey: ['shortlist', { url }],
		queryFn: async ({ jwtToken, queryKey }) => {
			const url = queryKey[1].url
			const s = await apiClient.getShortlistByUrl(url, jwtToken)
			// The API Client returns a Shortlist object instance, for react-query we just want the underlying data instead
			return s.data
		},
		enabled: !!url,
		staleTime: 15 * 60 * 1000, // Valid 15 minutes
	}
}

function selectRoomsCombinations(shortlist: DetailedShortlistResult) {
	return new Set(shortlist.propositions.map((p) => hashRoomList(p.rooms)))
}

export const roomsCombinationsInCurrentList: AuthQueryFactory<
	[],
	DetailedShortlistResult,
	Set<string>,
	CurrentShortlistQueryKey
> = () => {
	return {
		queryKey: ['shortlist', 'current'],
		queryFn: async ({ jwtToken }) => {
			return await apiClient.getCurrentShortlist(jwtToken)
		},
		staleTime: 15 * 60 * 1000, // Valid 15 minutes
		select: selectRoomsCombinations,
		notifyOnChangeProps: ['data'],
	}
}

type ShortlistQueryKey = ['shortlist', { id: string }]

export const shortlistQuery: AuthQueryFactory<
	[id: string],
	DetailedShortlistResult,
	DetailedShortlistResult,
	ShortlistQueryKey
> = ({ queryClient }, id) => {
	return {
		queryKey: ['shortlist', { id }],
		queryFn: async ({ jwtToken, queryKey }) => {
			const id = queryKey[1].id
			const s = await apiClient.getShortlistById(id, jwtToken)
			// The API Client returns a Shortlist object instance, for react-query we just want the underlying data instead
			return s.data
		},
		initialData: () => {
			const currentShortlist =
				queryClient.getQueryData<DetailedShortlistResult>([
					'shortlist',
					'current',
				])
			if (currentShortlist !== undefined && currentShortlist.id === id) {
				return currentShortlist
			}
			return undefined
		},
		enabled: !!id,
		staleTime: 15 * 60 * 1000, // Valid 15 minutes
	}
}

type PropositionQueryKey = ['proposition', { id: string }]

export const propositionQuery: AuthQueryFactory<
	[id: string],
	PropositionResult,
	PropositionResult,
	PropositionQueryKey
> = ({ queryClient }, id) => {
	return {
		queryKey: ['proposition', { id }],
		queryFn: async ({ jwtToken, queryKey }) => {
			const propositionId = queryKey[1].id
			return await apiClient.getPropositionById(propositionId, jwtToken)
		},
		staleTime: 15 * 60 * 1000, // Valid 15 minutes
		initialData: () => {
			return queryClient
				.getQueryData<DetailedShortlistResult>(['shortlist', 'current'])
				?.propositions.find((p) => p.id === id)
		},
	}
}
