import {
	HomepageRoute,
	OfficeHelpRoute,
	CoworkingSerpRoute,
	CoworkingLocationRoute,
	CurrentShortlistRoute,
	OthersShortlistRoute,
	SinglePropositionRoute,
	NotFoundPageRoute,
	AccountActionRoute,
	ManageMembers,
	Invitation,
	WhatIsCoworking,
	MakeYourCoworkingGrow,
	HybridWork,
	CoworkingOperatorRoute,
} from '~/routes/index'
import { BaseComponent, BaseHead } from './baseroute'
import type { RouteConfig } from './utils/routing'

const routes: RouteConfig<Record<string, never>>[] = [
	{
		component: BaseComponent,
		helmet: BaseHead,
		routes: [
			HomepageRoute,
			CoworkingSerpRoute,
			CoworkingLocationRoute,
			CurrentShortlistRoute,
			OthersShortlistRoute,
			OfficeHelpRoute,
			SinglePropositionRoute,
			AccountActionRoute,
			ManageMembers,
			Invitation,
			WhatIsCoworking,
			MakeYourCoworkingGrow,
			HybridWork,
			CoworkingOperatorRoute,
			NotFoundPageRoute,
		],
	},
]

export default routes
