import type { QueryFunction, UseQueryOptions } from 'react-query'
import type {
	CoworkingSearchResult,
	NominatimFeatureCollection,
	NearbyArea,
} from '@alexanderathoodly/data-models'
import { apiClient } from '~/utils/requests'
import type { SolutionCriteria } from '~/queries/coworking_solution'

type CoworkingSearchResultQueryKey = [
	'coworking_serch_result',
	SolutionCriteria
]

const fetchCoworkingSearchResult: QueryFunction<
	CoworkingSearchResult,
	CoworkingSearchResultQueryKey
> = async ({ queryKey }) => {
	const criteria = queryKey[1]
	return await apiClient.getCoworkingSearchResult(criteria)
}

export const getCoworkingSearchResultQuery: (
	criteria: SolutionCriteria
) => UseQueryOptions<
	CoworkingSearchResult,
	Error,
	CoworkingSearchResult,
	CoworkingSearchResultQueryKey
> = (criteria) => {
	delete criteria.page
	return {
		queryKey: ['coworking_serch_result', criteria],
		queryFn: fetchCoworkingSearchResult,
		staleTime: 15 * 60 * 1000, // Valid 15 minutes
		enabled:
			(criteria.areas && criteria.areas.length > 0) ||
			criteria.area_url != undefined ||
			false,
		retryOnMount: false,
	}
}

type CoworkingAreaFeaturesQueryKey = [
	'areaFeatures',
	{ ids: string[]; area_url?: string | undefined }
]

const fetchAreaFeatures: QueryFunction<
	NominatimFeatureCollection,
	CoworkingAreaFeaturesQueryKey
> = async ({ queryKey }) => {
	const query = queryKey[1]
	const params: URLSearchParams = query.area_url
		? new URLSearchParams([['area_url', query.area_url]])
		: query.ids.reduce((params, area) => {
				params.append('area', area)
				return params
		  }, new URLSearchParams())
	return await apiClient.getAreaFeatures(params.toString())
}

export const getCoworkingAreaFeaturesQuery: (
	ids: string[],
	area_url?: string | undefined
) => UseQueryOptions<
	NominatimFeatureCollection,
	Error,
	NominatimFeatureCollection,
	CoworkingAreaFeaturesQueryKey
> = (ids, area_url) => {
	return {
		queryKey: ['areaFeatures', { ids, area_url }],
		queryFn: fetchAreaFeatures,
		staleTime: 5 * 60 * 1000,
		enabled: ids.length > 0 || area_url != undefined,
	}
}

type NearbyAreasQueryKey = ['nearbyAreas', string]

export const getNearbyAreasQuery: (
	city: string
) => UseQueryOptions<NearbyArea[], Error, NearbyArea[], NearbyAreasQueryKey> = (
	city
) => {
	return {
		queryKey: ['nearbyAreas', city],
		queryFn: async () => await apiClient.getNearbyAreas(city),
		staleTime: 12 * 60 * 60 * 1000,
		enabled: !!city,
	}
}
