import { useState } from 'react'
import {
	GoogleAuthProvider,
	OAuthProvider,
	signInWithPopup,
	createUserWithEmailAndPassword,
	signInWithEmailAndPassword,
	fetchSignInMethodsForEmail,
	EmailAuthProvider,
	linkWithCredential,
} from 'firebase/auth'
import type { UserCredential, AuthCredential, AuthError } from 'firebase/auth'
import { useAuth } from 'reactfire'
import type { FirebaseError } from 'firebase/app'

type IdleFormState = {
	status: 'idle'
	error?: undefined
}

type LoadingFormState = {
	status: 'loading'
	error?: undefined
}

type ErrorFormState = {
	status: 'error'
	error: FirebaseError
}

type SuccessFormState = {
	status: 'success'
	error?: undefined
}

type SignInState = {
	type: 'signIn'
	pending?: undefined
}

type SignUpState = {
	type: 'signUp'
	pending?: undefined
}

type resetPasswordState = {
	type: 'resetPassword'
	pending?: undefined
}

type LinkState = {
	type: 'linkCredentials'
	pending: {
		cred: AuthCredential
		signInMethod: string
		email: string
	}
}

type ProgressState =
	| IdleFormState
	| LoadingFormState
	| ErrorFormState
	| SuccessFormState
type FormKindState = SignInState | SignUpState | resetPasswordState | LinkState
type SignInUpState = ProgressState & FormKindState

type SignInUpOpts = {
	defaultType: 'signUp' | 'signIn' | 'resetPassword'
}

/**
 * An override of the standard AuthError to indicate to typescript that we do have the `credentials` set here
 * This is the official way Google wants to do it as seen in https://cloud.google.com/identity-platform/docs/link-accounts#handling_the_account-exists-with-different-credential_error
 */
interface AccountExistWithDifferentCredentialsError extends Error {
	readonly code: 'auth/account-exists-with-different-credential'
	readonly credential: AuthCredential
	readonly name: 'FirebaseError'
	readonly appName: string
	readonly email?: string
	readonly phoneNumber?: string
	readonly tenantid?: string
}

type FirebaseAuthError = AccountExistWithDifferentCredentialsError | AuthError

function isFirebaseError(e: any): e is FirebaseAuthError {
	return 'name' in e && 'code' in e
}

function isDifferentCredentialsError(
	e: FirebaseAuthError
): e is AccountExistWithDifferentCredentialsError {
	return e.code === 'auth/account-exists-with-different-credential'
}

export type UseSignInUpForm = {
	passwordSignIn: (
		email: string,
		password: string
	) => Promise<UserCredential | FirebaseAuthError>
	passwordSignUp: (
		email: string,
		password: string
	) => Promise<UserCredential | FirebaseAuthError>
	googleSignIn: () => Promise<UserCredential | FirebaseAuthError>
	microsoftSignIn: () => Promise<UserCredential | FirebaseAuthError>
	toSignIn: () => void
	toSignUp: () => void
	toResetPassword: () => void
	status: string
	error: FirebaseError | undefined
	pending:
		| { cred: AuthCredential; signInMethod: string; email: string }
		| undefined
	type: string
}

export function useSignInUpForm(opts: SignInUpOpts): UseSignInUpForm {
	const auth = useAuth()
	const [state, setState] = useState<SignInUpState>({
		status: 'idle',
		type: opts.defaultType,
	})
	function makeAuthHandler<A extends any[]>(
		handler: (...a: A) => Promise<UserCredential>
	): (...a: A) => Promise<UserCredential | FirebaseAuthError> {
		return async (...args) => {
			setState({ ...state, status: 'loading', error: undefined })
			try {
				const result = await handler(...args)
				if (
					state.type === 'linkCredentials' &&
					result.user.email === state.pending.email
				) {
					await linkWithCredential(result.user, state.pending.cred)
				}
				setState({ ...state, status: 'success', error: undefined })
				return result
			} catch (e) {
				await handleSignInError(e as FirebaseAuthError)
				return e as FirebaseAuthError
			}
		}
	}
	const handleSignInError = async (e: FirebaseAuthError) => {
		if (isDifferentCredentialsError(e)) {
			const pendingCred = e.credential
			const email = e.email as string
			const signInMethod =
				(await fetchSignInMethodsForEmail(auth, email))[0] || ''
			setState({
				status: 'idle',
				type: 'linkCredentials',
				pending: { cred: pendingCred, signInMethod, email },
			})
		} else {
			setState({ ...state, status: 'error', error: e })
		}
	}
	const googleSignIn = makeAuthHandler(async () => {
		const provider = new GoogleAuthProvider()
		return await signInWithPopup(auth, provider)
	})
	const microsoftSignIn = makeAuthHandler(async () => {
		const provider = new OAuthProvider('microsoft.com')
		return await signInWithPopup(auth, provider)
	})
	const passwordSignIn = makeAuthHandler(
		async (email: string, password: string) => {
			return await signInWithEmailAndPassword(auth, email, password)
		}
	)
	const passwordSignUp = async (email: string, password: string) => {
		/**
		 * This particular case is handled separately because of the way password sign up works
		 * If we already have a user for that email (from ex gmail) we cannot get a pending credential
		 * And we receive a different error that needs to be handled by creating a credential manually
		 */
		setState({ ...state, status: 'loading', error: undefined })
		try {
			const createdUser = await createUserWithEmailAndPassword(
				auth,
				email,
				password
			)
			setState({ ...state, status: 'success', error: undefined })
			return createdUser
		} catch (e) {
			if (!isFirebaseError(e)) {
				throw e
			}
			if (e.code === 'auth/email-already-in-use') {
				// https://stackoverflow.com/questions/58422400/firebase-authentication-email-already-in-use-error
				// This error could be valid if you are trying to create a password account and it already exists
				// But it could also be that you logged in previously with another provider
				// We will check if the existing account uses password login, and if not try to link it
				const signInMethods = await fetchSignInMethodsForEmail(auth, email)
				if (signInMethods.indexOf('password') > -1) {
					// There is already a user using password for that email
					setState({ ...state, status: 'error', error: e })
				}
				setState({
					status: 'idle',
					type: 'linkCredentials',
					pending: {
						cred: EmailAuthProvider.credential(email, password),
						signInMethod: signInMethods[0] || '',
						email,
					},
				})
			} else {
				setState({
					...state,
					status: 'error',
					error: e,
				})
			}
			return e
		}
	}

	const toSignIn = () => setState({ type: 'signIn', status: 'idle' })
	const toSignUp = () => setState({ type: 'signUp', status: 'idle' })
	const toResetPassword = () =>
		setState({ type: 'resetPassword', status: 'idle' })
	return {
		passwordSignIn,
		passwordSignUp,
		googleSignIn,
		microsoftSignIn,
		toSignIn,
		toSignUp,
		toResetPassword,
		status: state.status,
		error: state.error,
		pending: state.pending,
		type: state.type,
	}
}
