import { useEffect } from 'react'
import type { FC, ReactElement } from 'react'
import { useQueryClient } from 'react-query'

const SyncQueryCacheStateToExtension: FC<{
	children: ReactElement
}> = ({ children }) => {
	const queryClient = useQueryClient()
	const queryCache = queryClient.getQueryCache()

	useEffect(() => {
		const unsubscribe = queryCache.subscribe((event) => {
			if (event?.type !== 'queryUpdated') {
				// We only care about data being changed, not observers or query deletion / addition
				return
			}
			const data = queryCache
				.findAll()
				.map((query) => {
					return {
						queryKey: query.queryKey,
						queryHash: query.queryHash,
						state: query.state,
					}
				})
				.filter((q) => !q.state.error)

			if (data.length) {
				try {
					window.postMessage(
						{
							type: 'APPLICATION_QUERY_CACHE_CHANGED',
							data: data,
						},
						'*'
					)
				} catch (e) {
					console.log(
						'Error occured while syncing query cache to chrome extension',
						e
					)
				}
			}
		})

		return unsubscribe
	}, [queryCache])

	return children
}

export default SyncQueryCacheStateToExtension
