import { lazy } from 'react'
import OfficeHelpHead from './helmet'

const OfficeHelp = lazy(() => import('~/routes/officehelp/index'))

const route = {
	path: [
		'/kontorshjalpen*',
		'/kontorshotellshjalpen*',
		'/coworkinghjalpen*',
		'/en/officehelp*',
		'/en/servicedofficehelp*',
		'/en/coworkinghelp*',
	],
	exact: true,
	component: OfficeHelp,
	helmet: OfficeHelpHead,
}

export default route
