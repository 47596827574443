import type { RouteConfig } from '~/utils/routing'
import { lazy } from 'react'

const NotFoundPageLayout = lazy(() => import('./layout'))

const route: RouteConfig<Record<string, never>> = {
	component: NotFoundPageLayout,
}

export default route
