import { lazy } from 'react'
import type { RouteConfig } from '~/utils/routing'

const WhatIsCoworkingLayout = lazy(() => import('./pages/whatiscoworking'))
const MakeYourCoworkingGrowLayot = lazy(
	() => import('./pages/makeyourcoworkinggrow')
)
const HybridWorkLayot = lazy(() => import('./pages/hybridwork'))

export const MakeYourCoworkingGrow: RouteConfig<Record<never, string>> = {
	path: ['/content/väx-er-coworking', '/en/content/make-your-coworking-grow'],
	exact: true,
	component: MakeYourCoworkingGrowLayot,
}

export const WhatIsCoworking: RouteConfig<Record<never, string>> = {
	path: ['/content/vad-är-coworking', '/en/content/what-is-coworking'],
	exact: true,
	component: WhatIsCoworkingLayout,
}

export const HybridWork: RouteConfig<Record<never, string>> = {
	path: ['/content/hybridarbete', '/en/content/hybrid-work'],
	exact: true,
	component: HybridWorkLayot,
}
