import { lazy } from 'react'
import type { RouteConfig } from '~/utils/routing'

const Layout = lazy(() => import('./layout'))

const route: RouteConfig<{ id: string }> = {
	path: '/shortlist/solutions/:id',
	exact: true,
	component: Layout,
}

export default route
