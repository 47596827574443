import { useEffect, useRef } from 'react'
import type { FC, PropsWithChildren } from 'react'
import { useHistory } from 'react-router-dom'

/**
 * The purpose of ScrollManager is to control the vertical position of a page.
 * When URL changes, the default behavior of scrolling position is to remain
 * at the same position of a previous page. However, when an user moves to a
 * new page, we want to scroll up the page to top.
 */
const ScrollManager: FC<PropsWithChildren> = ({ children }) => {
	const history = useHistory()
	const previousPath = useRef(history.location.pathname)

	useEffect(() => {
		return history.listen((location, action) => {
			if (action == 'PUSH') {
				if (previousPath.current !== location.pathname) {
					window.scrollTo(0, 0)
				}
			}
			previousPath.current = location.pathname
		})
	}, [history, previousPath])
	return <>{children}</>
}

export default ScrollManager
