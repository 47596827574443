function setGAMetrics(metrics: {
	[key: string]: string | number | undefined
}): void {
	ga('set', metrics)
}

function sendEvent(
	category: string,
	action: string,
	label: string | null,
	metrics: { [key: string]: string | number | undefined }
): void {
	ga('send', 'event', category, action, label, metrics)
}

function sendGA4Event(
	eventName: string,
	params:
		| Gtag.CustomParams
		| Gtag.ControlParams
		| Gtag.EventParams
		| Gtag.ConfigParams
		| undefined
): void {
	void gtag('event', eventName, params)
}

function GAAction({
	category,
	action,
	label = null,
	metrics = {},
}: {
	category: string
	action: string
	label?: string | null
	metrics?: { [key: string]: string | number | undefined }
}): void {
	// Set the LPType dimension in GA when we are on the LP and change the type
	if (action === 'selectLPType' && window.location.pathname.length < 2) {
		ga('set', 'dimension1', label)
		return
	}
	sendEvent(category, action, label, metrics)
}

export { setGAMetrics, GAAction, sendGA4Event }
