import type { ReactNode } from 'react'
import { Helmet } from 'react-helmet-async'
import type { MatchedRoute } from './utils/routing'

/* 
This function helps generating the head, both on the client and the server. 
The concept is that it takes a branch, result of a match route, 
and returns a list of react helmet components in the right order to be used.
*/

function composeHead(
	branch: MatchedRoute<Record<string, string>>[]
): ReactNode[] {
	return branch.map(({ route }, i) => {
		if (!route.helmet) {
			return null
		}
		return <Helmet key={i} {...route.helmet()} />
	})
}

export default composeHead
