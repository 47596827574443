import { lazy } from 'react'
import type { RouteConfig } from '~/utils/routing'

const Layout = lazy(() => import('./layout'))

const route: RouteConfig<Record<string, never>> = {
	path: '/my_account/members',
	exact: false,
	component: Layout,
}

export default route
