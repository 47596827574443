import { startTransition, useEffect, useMemo, useState } from 'react'
import type { FC } from 'react'
import { getAuth } from 'firebase/auth'
import { FirebaseAppProvider, AuthProvider, useFirebaseApp } from 'reactfire'
import { initializeApp } from 'firebase/app'

const AuthConfig: FC<{ children: JSX.Element }> = ({ children }) => {
	const app = useFirebaseApp()
	const auth = getAuth(app)
	return <AuthProvider sdk={auth}>{children}</AuthProvider>
}

function dummyFirebase() {
	const app = initializeApp({ apiKey: 'dummy' }, 'dummy')
	return app
}

export const FirebaseConfig: FC<{ children: JSX.Element }> = ({ children }) => {
	const [isRendered, setIsRendered] = useState(false)
	useEffect(() => {
		startTransition(() => setIsRendered(true))
	}, [])
	const config = useMemo(() => {
		const app = typeof window === 'undefined' ? undefined : window.firebaseApp
		const config = app
			? { firebaseApp: app }
			: {
					firebaseConfig: {
						apiKey: import.meta.env.VITE_FIREBASE_APIKEY,
						authDomain: import.meta.env.VITE_FIREBASE_AUTHDOMAIN,
					},
			  }
		return isRendered ? config : { firebaseApp: dummyFirebase() }
	}, [isRendered])
	return (
		<FirebaseAppProvider {...config}>
			<AuthConfig>{children}</AuthConfig>
		</FirebaseAppProvider>
	)
}
