// Since this file has functions which render a React component,
// the filename extension is ".tsx" unlike the other corresponding
// route files (route.ts).
import { lazy } from 'react'
import type { FC } from 'react'
import type { RouteConfig } from '~/utils/routing'
import { useAuthQuery } from '~/queries/useauthquery'
import { currentShortlistQuery, shortlistByUrlQuery } from '~/queries/user_hub'
import type { RouteComponentProps } from 'react-router-dom'

const Layout = lazy(() => import('./layout'))

const CurrentShortlistWrapper: FC = () => {
	const result = useAuthQuery(currentShortlistQuery)
	return <Layout result={result} />
}

const ShortlistByURLWrapper: FC<
	RouteComponentProps<{ shortlistURL: string }>
> = ({ match }) => {
	const result = useAuthQuery(shortlistByUrlQuery, match.params.shortlistURL)
	return <Layout result={result} />
}

export const CurrentShortlistRoute: RouteConfig<Record<never, string>> = {
	path: '/my_account/solutions_list',
	exact: true,
	component: CurrentShortlistWrapper,
}

export const OthersShortlistRoute: RouteConfig<{ shortlistURL: string }> = {
	path: '/shortlist/:shortlistURL',
	exact: true,
	component: ShortlistByURLWrapper,
}
