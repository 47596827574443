import { lazy } from 'react'

const HomePage = lazy(() => import('~/routes/home_page/index'))

const route = {
	path: ['/', '/en'],
	exact: true,
	component: HomePage,
}

export default route
