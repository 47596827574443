import type { FC } from 'react'
import { Route } from 'react-router-dom'
import { ErrorPage } from '~/layouts/pages'

function Status({ code, children }: { code: number; children: JSX.Element }) {
	return (
		<Route
			render={({ staticContext }) => {
				if (staticContext) staticContext.statusCode = code
				return children
			}}
		/>
	)
}

const NotFoundPageLayout: FC = () => {
	return (
		<Status code={404}>
			<ErrorPage code={404} />
		</Status>
	)
}

export default NotFoundPageLayout
