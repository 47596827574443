import type { QueryFunction, UseQueryOptions } from 'react-query'
import type {
	ProviderResult,
	ServiceOfficeTestimonialResult,
} from '@alexanderathoodly/data-models'
import { apiClient } from '~/utils/requests'

type ProviderQueryKey = ['provider', { id: string | undefined }]

const fetchProvider: QueryFunction<ProviderResult, ProviderQueryKey> = async ({
	queryKey,
}) => {
	const { id } = queryKey[1]
	return await apiClient.getServicedOfficeProviderById(id as string) // casting is fine as `enabled` prevents the id to be undefined
}

export const getProviderQuery: (
	id: string | undefined
) => UseQueryOptions<
	ProviderResult,
	Error,
	ProviderResult,
	ProviderQueryKey
> = (id) => {
	return {
		queryKey: ['provider', { id }],
		queryFn: fetchProvider,
		staleTime: 60000,
		enabled: !!id,
	}
}

type TestimonialsQueryKey = [
	'testimonials',
	{ locations: string[] | undefined }
]

const fetchTestimonials: QueryFunction<
	ServiceOfficeTestimonialResult[],
	TestimonialsQueryKey
> = async ({ queryKey }) => {
	const res = await Promise.all(
		queryKey[1].locations?.length
			? queryKey[1].locations.map((location) =>
					apiClient.getServicedOfficeTestimonials({
						location, // casting is fine as `enabled` prevents the id to be undefined
					})
			  )
			: []
	)
	return res.flat(1)
}

export const getTestimonialsQuery: (
	locations: string[] | undefined
) => UseQueryOptions<
	ServiceOfficeTestimonialResult[],
	Error,
	ServiceOfficeTestimonialResult[],
	TestimonialsQueryKey
> = (locationIds) => {
	return {
		queryKey: ['testimonials', { locations: locationIds }],
		queryFn: fetchTestimonials,
		staleTime: 60000,
		enabled: !!locationIds,
	}
}
