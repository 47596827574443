import type { RouteConfig } from '~/utils/routing'
import {
	extractCoworkingSerpParams,
	useCoworkingSerpParams,
	usePagination,
} from './utils'
import { SerpFilterUpdateSessionProvider } from './updateFilters'
import { lazy } from 'react'
import type { FC } from 'react'
import type { RouteComponentProps } from 'react-router-dom'
import {
	getCoworkingAreaFeaturesQuery,
	getCoworkingSearchResultQuery,
	getNearbyAreasQuery,
} from '~/queries/coworking_search_result'
import { FILTER_TYPE_OFFICE, FILTER_TYPE_MEMBERSHIP } from './urlGenerator'
import { useQuery } from 'react-query'
import type { StatusError } from '@alexanderathoodly/data-models'
import NotFoundPageLayout from '../notfound/layout'
import { ErrorPage } from '~/layouts/pages'

const CoworkingSerpLayout = lazy(() => import('./layout'))

const CoworkingSerp: FC<
	RouteComponentProps<{ city: string; neighborhood: string }>
> = (props) => {
	const criteria = useCoworkingSerpParams()
	const serpData = useQuery(
		getCoworkingSearchResultQuery({
			...criteria,
			type:
				criteria.type !== FILTER_TYPE_OFFICE &&
				criteria.type !== FILTER_TYPE_MEMBERSHIP
					? undefined
					: criteria.type,
		})
	)
	const { numberOfPages } = usePagination({
		locations: serpData.data?.locations,
	})
	if (serpData.status == 'error') {
		if (
			(serpData.error as StatusError).statusCode == 400 ||
			(serpData.error as StatusError).statusCode == 404
		)
			return <NotFoundPageLayout />
		return <ErrorPage error={serpData.error} />
	}
	if (numberOfPages && criteria.page && criteria.page > numberOfPages) {
		return <NotFoundPageLayout />
	}

	return (
		<SerpFilterUpdateSessionProvider>
			<CoworkingSerpLayout {...props} />
		</SerpFilterUpdateSessionProvider>
	)
}

const route: RouteConfig<{ city: string; neighborhood: string }> = {
	path: [
		'/lediga-kontor',
		'/lediga-kontor/:city',
		'/lediga-kontor/:city/:neighborhood',
		'/lediga-kontorshotell',
		'/lediga-kontorshotell/:city',
		'/lediga-kontorshotell/:city/:neighborhood',
		'/coworking-spaces',
		'/coworking-spaces/:city',
		'/coworking-spaces/:city/:neighborhood',
		'/en/office-space',
		'/en/office-space/:city',
		'/en/office-space/:city/:neighborhood',
		'/en/serviced-office',
		'/en/serviced-office/:city',
		'/en/serviced-office/:city/:neighborhood',
		'/en/coworking-spaces',
		'/en/coworking-spaces/:city',
		'/en/coworking-spaces/:city/:neighborhood',
	],
	exact: true,
	component: CoworkingSerp,
	prefetchQueries: async (match, location, client) => {
		const searchParams = new URLSearchParams(location.search)
		const criteria = extractCoworkingSerpParams(
			searchParams,
			match.params.city,
			match.params.neighborhood
		)
		const prefetchQueries = []
		prefetchQueries.push(
			client.prefetchQuery(
				getCoworkingSearchResultQuery({
					...criteria,
					type:
						criteria.type !== FILTER_TYPE_OFFICE &&
						criteria.type !== FILTER_TYPE_MEMBERSHIP
							? undefined
							: criteria.type,
				})
			),
			client.prefetchQuery(
				getCoworkingAreaFeaturesQuery(
					searchParams.getAll('area'),
					match.params.neighborhood
						? `${match.params.city}/${match.params.neighborhood}`
						: match.params.city
						? match.params.city
						: !criteria.areas.length
						? 'stockholm'
						: undefined
				)
			),
			client.prefetchQuery(getNearbyAreasQuery(match.params.city))
		)

		await Promise.all(prefetchQueries)
	},
}

export default route
