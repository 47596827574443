import type { CoworkingLocation } from '@alexanderathoodly/data-models'
import { useMemo } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useSearchParams } from '~/utils/routing'
import {
	parseSorting,
	parseFilterType,
	FILTER_TYPE_ALL,
	FILTER_TYPE_MEMBERSHIP,
	FILTER_TYPE_OFFICE,
	COWORKING_TYPE,
	OFFICE_TYPE,
	SERVICED_OFFICE_TYPE,
	TYPE_CHOICE,
} from './urlGenerator'
import type { SORTING_CHOICE, FILTER_TYPE_CHOICE } from './urlGenerator'
import { useTranslation } from 'react-i18next'

/**
 * In the case when type is undefined, we will set filter value to all,
 * the reason for having undefined and all is to differentiate if the user has
 * landed in the SERP without any type filter or actively selected "all" and
 * make changes in the resultcard UI depending on the case.
 */
export type CoworkingSearchParams = {
	area_url: string | undefined
	areas: string[]
	workspaces?: number | undefined
	budget?: number | undefined
	services: string[]
	sorting: SORTING_CHOICE
	type: FILTER_TYPE_CHOICE | undefined
	page?: number | undefined
}

/* In case there is a neighborhood param after "/:city "
  such as "/lediga-kontorshotell/stockholm/vasastan", "vasastan" (url)
  should be translated into area ID so that the neighborhood can be included
  in areaCodes in addition to normal area param such as "area=6&area=8". 
  This idea is applicable to both extractCoworkingSerpParams 
  and useCoworkingSerpParams.
  If the neighborhood param indicates an unexisting area such as "berlin" or "madrid",
  the entire url should be considered as invalid with "isValidUrl = false". 
*/

export function extractCoworkingSerpParams(
	params: URLSearchParams,
	city?: string,
	neighborhood?: string
): CoworkingSearchParams {
	const areaCodes = params.getAll('area')
	const workspaces = params.get('ws')
	const budget = params.get('budget')
	const serviceCodes = params.getAll('service')
	const sorting = parseSorting(params.get('sort') || '')
	let type = params.get('type')
		? parseFilterType(params.get('type') || '')
		: undefined
	const page = params.get('page')

	//If someone was to manipulate type in the the url, we just don't use that value instead of getting an error
	if (
		type &&
		![FILTER_TYPE_OFFICE, FILTER_TYPE_MEMBERSHIP, FILTER_TYPE_ALL].includes(
			type
		)
	) {
		type = undefined
	}
	// city and neighborhood are for landing pages once user has filtered for areas,
	// areaCodes should be used and in the case of just /{office-type} default to stockholm
	const area_url = areaCodes.length
		? undefined
		: city && neighborhood
		? `${city}/${neighborhood}`
		: city
		? `${city}`
		: 'stockholm'

	return {
		areas: areaCodes,
		area_url,
		workspaces: workspaces ? Number(workspaces) : undefined,
		budget: budget ? Number(budget) : undefined,
		services: serviceCodes,
		sorting,
		type,
		page: page ? Number(page) : undefined,
	}
}

export function useCoworkingSerpParams(): CoworkingSearchParams {
	const { city, neighborhood } = useParams<{
		city: string
		neighborhood: string
	}>()
	const searchParams = useSearchParams()

	return useMemo(() => {
		return extractCoworkingSerpParams(searchParams, city, neighborhood)
	}, [searchParams, neighborhood, city])
}

export function useSortSelector(): {
	sortMode: string
	onChangeSortSelector: (newSortMode: string) => void
	sortOptions: {
		value: string
		text: string
	}[]
} {
	const { t } = useTranslation()
	const history = useHistory()
	const searchParams = new URLSearchParams(history.location.search)
	const sortMode = searchParams.get('sort') || 'default'
	const onChangeSortSelector = (newSortMode: string): void => {
		if (newSortMode == 'asc' || newSortMode == 'desc') {
			searchParams.set('sort', newSortMode)
		} else {
			searchParams.delete('sort')
		}
		history.push(`${history.location.pathname}?${searchParams.toString()}`)
	}
	const sortOptions = [
		{ value: 'asc', text: t('Hyra - lägst till högst') },
		{ value: 'desc', text: t('Hyra - högst till lägst') },
		{ value: 'default', text: t('Rekommenderad') },
	]

	return {
		sortMode,
		onChangeSortSelector,
		sortOptions,
	}
}

function getLocationSortPrice(
	location: CoworkingLocation,
	filterType: string,
	sortMode: string,
	workspaces: number | undefined
): number | null {
	const { min_rent_membership, min_rent_solution } = location
	const solutionPrice = min_rent_solution
		? workspaces
			? min_rent_solution.price
			: min_rent_solution.price / min_rent_solution.workspaces
		: null

	const membershipPrice = min_rent_membership
		? workspaces && workspaces > 1
			? min_rent_membership.price * workspaces
			: min_rent_membership.price
		: null

	if (filterType === FILTER_TYPE_ALL) {
		/** In case of "acs", we sort on cheapest result price ascending
		And if "desc", we sort on the most expensive on descending */
		if (membershipPrice && solutionPrice) {
			return membershipPrice < solutionPrice && sortMode === 'asc'
				? membershipPrice
				: solutionPrice
		}
		if (solutionPrice && solutionPrice > 1) {
			return solutionPrice
		}
		return membershipPrice
	}
	if (filterType === FILTER_TYPE_OFFICE) {
		return solutionPrice && solutionPrice > 1 ? solutionPrice : null
	}
	return membershipPrice
}

const NUMBER_OF_RESULT_PER_PAGE = 20

export function getPaginatedResult(
	coworkingSerpResult: CoworkingLocation[],
	page: number
): CoworkingLocation[] {
	const start = NUMBER_OF_RESULT_PER_PAGE * (page - 1)
	const end = NUMBER_OF_RESULT_PER_PAGE * page
	return coworkingSerpResult.slice(start, end)
}

export function usePagination({
	locations,
}: {
	locations: CoworkingLocation[] | undefined
}): {
	numberOfPages: number | undefined
	paginationRange: number[]
	currentPage: number
	currentPageResultText: string | undefined
	isNextPageExist: boolean | undefined
	isPreviousPageExist: boolean | undefined
	goToNextPage: () => void
	goToPreviousPage: () => void
	getPageLink: (page: number) => string
} {
	const { t } = useTranslation()
	const NUMBER_OF_SIDE_ELEMENTS = 2
	const history = useHistory()
	const searchParams = new URLSearchParams(history.location.search)
	const pageParam = searchParams.get('page')
	const currentPage = pageParam ? Number(pageParam) : 1
	const numberOfPages = locations
		? locations.length <= NUMBER_OF_RESULT_PER_PAGE
			? 1
			: Math.ceil(locations.length / NUMBER_OF_RESULT_PER_PAGE)
		: undefined

	const indexOfLeftSide = Math.max(currentPage - NUMBER_OF_SIDE_ELEMENTS, 1)
	const indexOfRightSide = locations
		? Math.min(currentPage + NUMBER_OF_SIDE_ELEMENTS, locations.length)
		: 0
	let paginationRange: number[] = []
	if (numberOfPages && numberOfPages >= 2) {
		if (numberOfPages <= NUMBER_OF_SIDE_ELEMENTS * 2 + 1) {
			paginationRange = Array.from({ length: numberOfPages }, (_v, i) => i + 1)
		} else {
			const shouldShowLeftDots = 1 < indexOfLeftSide
			const shouldShowRightDots = indexOfRightSide < numberOfPages
			// 0 means dots
			if (!shouldShowLeftDots && shouldShowRightDots) {
				paginationRange = [
					...Array.from(
						{ length: NUMBER_OF_SIDE_ELEMENTS * 2 },
						(_v, i) => i + 1
					),
					0,
					numberOfPages,
				]
			}
			if (shouldShowLeftDots && !shouldShowRightDots) {
				paginationRange = [
					1,
					0,
					...Array.from(
						{ length: NUMBER_OF_SIDE_ELEMENTS * 2 },
						(_v, i) => numberOfPages - i
					).reverse(),
				]
			}
			if (shouldShowLeftDots && shouldShowRightDots) {
				paginationRange = [
					1,
					0,
					...Array.from(
						{ length: NUMBER_OF_SIDE_ELEMENTS * 2 - 1 },
						(_v, i) => currentPage - (NUMBER_OF_SIDE_ELEMENTS - 1) + i
					),
					0,
					numberOfPages,
				]
			}
		}
	}

	const resultIndexOfPageStart =
		(currentPage - 1) * NUMBER_OF_RESULT_PER_PAGE + 1
	const resultIndexOfPageEnd = locations
		? currentPage == numberOfPages
			? locations.length
			: currentPage * NUMBER_OF_RESULT_PER_PAGE
		: undefined
	const currentPageResultText = resultIndexOfPageEnd
		? t('Resultat {{start}}-{{end}} (utav {{total_locations_number}})', {
				start: resultIndexOfPageStart,
				end: resultIndexOfPageEnd,
				total_locations_number: locations!.length,
		  })
		: undefined

	const getPageLink = (page: number) => {
		if (page == 1) {
			searchParams.delete('page')
		} else {
			searchParams.set('page', page.toString())
		}
		return searchParams.toString().length > 0
			? `${history.location.pathname}?${searchParams.toString()}`
			: history.location.pathname
	}
	const changePage = (page: number) => {
		history.push(getPageLink(page))
		window.scrollTo({ top: 0 })
	}
	const goToNextPage = () => {
		changePage(currentPage + 1)
	}
	const goToPreviousPage = () => {
		changePage(currentPage - 1)
	}
	return {
		numberOfPages,
		paginationRange,
		currentPage,
		currentPageResultText,
		isNextPageExist: numberOfPages ? currentPage < numberOfPages : undefined,
		isPreviousPageExist: currentPage > 1,
		goToNextPage,
		goToPreviousPage,
		getPageLink,
	}
}

export function getPaginationLinks(
	linkBase: string | undefined,
	currentPage: number | undefined,
	numberOfPages: number | undefined
): {
	canonicalLink: string | undefined
	nextLink: string | undefined
	previousLink: string | undefined
} {
	if (!linkBase || !numberOfPages) {
		return {
			canonicalLink: undefined,
			nextLink: undefined,
			previousLink: undefined,
		}
	}
	if (numberOfPages == 1) {
		return {
			canonicalLink: linkBase,
			nextLink: undefined,
			previousLink: undefined,
		}
	}
	// on the first page
	if (!currentPage) {
		return {
			canonicalLink: linkBase,
			nextLink: `${linkBase}?page=2`,
			previousLink: undefined,
		}
	}
	if (currentPage < numberOfPages) {
		return {
			canonicalLink: `${linkBase}?page=${currentPage}`,
			nextLink: `${linkBase}?page=${currentPage + 1}`,
			previousLink:
				currentPage == 2 ? linkBase : `${linkBase}?page=${currentPage - 1}`,
		}
	}
	if (currentPage == numberOfPages) {
		return {
			canonicalLink: `${linkBase}?page=${currentPage}`,
			nextLink: undefined,
			previousLink:
				currentPage == 2 ? linkBase : `${linkBase}?page=${currentPage - 1}`,
		}
	}
	return {
		canonicalLink: undefined,
		nextLink: undefined,
		previousLink: undefined,
	}
}

export function sortCoworkingSerpResult(
	coworkingSerpResult: CoworkingLocation[],
	sortMode: string,
	workspaces: number | undefined,
	filterType: string
): CoworkingLocation[] {
	switch (sortMode) {
		case 'asc':
			return coworkingSerpResult.slice().sort((a, b) => {
				const locationAPrice = getLocationSortPrice(
					a,
					filterType,
					sortMode,
					workspaces
				)
				const locationBPrice = getLocationSortPrice(
					b,
					filterType,
					sortMode,
					workspaces
				)
				if (locationBPrice === null) {
					return -1
				}

				return (locationAPrice || Infinity) - (locationBPrice || Infinity)
			})
		case 'desc':
			return coworkingSerpResult.slice().sort((a, b) => {
				const locationAPrice =
					getLocationSortPrice(a, filterType, sortMode, workspaces) || 0
				const locationBPrice =
					getLocationSortPrice(b, filterType, sortMode, workspaces) || 0

				if (locationBPrice === 0) {
					return -1
				}
				return locationBPrice - locationAPrice
			})
		default:
			return coworkingSerpResult
				.filter((serpResult) => serpResult.solutions_count)
				.concat(
					coworkingSerpResult.filter(
						(serpResult) => !serpResult.solutions_count
					)
				)
	}
}

export function getRandomLocations(
	locations: CoworkingLocation[],
	locationCount: number
): CoworkingLocation[] {
	const copiedLocations = locations.slice()
	const randomLocations: CoworkingLocation[] = []
	while (randomLocations.length < locationCount) {
		const r = Math.floor(Math.random() * copiedLocations.length)
		randomLocations.push(copiedLocations[r]!)
		copiedLocations.splice(r, 1)
	}
	return randomLocations
}

export function getOperatorCount(locations: CoworkingLocation[]): number {
	return [...new Set(locations.map((location) => location.provider_name))]
		.length
}

export function formatNamesArray(names: string[]): string {
	let formattedNames = ''
	for (let i = 0; i < names.length; i++) {
		if (!names[i]) {
			break
		}
		formattedNames += `${
			i == names.length - 1 && i > 0 ? ' och ' : i != 0 ? ', ' : ''
		}${names[i]!}`
	}
	return formattedNames
}

export function useSerpPageTypeInSwedish(): TYPE_CHOICE {
	const { i18n, t } = useTranslation()
	const { pathname } = useLocation()
	const pathnames = pathname.split('/')
	const pageType = i18n.language == 'sv' ? pathnames[1] : pathnames[2]
	if (pageType == t(COWORKING_TYPE, { ns: 'url' })) {
		return COWORKING_TYPE
	}
	if (pageType == t(SERVICED_OFFICE_TYPE, { ns: 'url' })) {
		return SERVICED_OFFICE_TYPE
	}
	return OFFICE_TYPE
}
