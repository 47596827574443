import { createContext, useContext, useState, useEffect, FC } from 'react'
import type { PropsWithChildren } from 'react'
import { useLocation } from 'react-router-dom'

type TRouterContext = {
	to: string | undefined
	from: string | undefined
}

const RouterContext = createContext<TRouterContext>({
	to: undefined,
	from: undefined,
})

export const useRouterContext: () => TRouterContext = () =>
	useContext(RouterContext)

export const RouterProvider: FC<PropsWithChildren> = ({ children }) => {
	const location = useLocation()
	const [route, setRoute] = useState({
		to: location.pathname,
		from: location.pathname,
	})

	useEffect(() => {
		setRoute((prev) => ({ to: location.pathname, from: prev.to }))
	}, [location])

	return (
		<RouterContext.Provider value={route}>{children}</RouterContext.Provider>
	)
}
