import type { FC } from 'react'
import {
	Button,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	useDisclosure,
	Drawer,
	DrawerBody,
	DrawerHeader,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
	Avatar,
} from '@chakra-ui/react'
import type { ChakraComponent, ButtonProps } from '@chakra-ui/react'
import { useUser } from 'reactfire'
import { SignOutButton } from '~/auth/signout'
import { SignInUpForm } from '~/auth/signinupform'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const SignInButton: ChakraComponent<'button', ButtonProps> = (props) => {
	const { t } = useTranslation()
	const { isOpen, onOpen, onClose } = useDisclosure()
	return (
		<>
			<Button onClick={onOpen} {...props}>
				{t('Logga in')}
			</Button>
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay></ModalOverlay>
				<ModalContent>
					<ModalCloseButton></ModalCloseButton>
					<ModalHeader></ModalHeader>
					<ModalBody>
						<SignInUpForm defaultType="signIn" />
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	)
}

interface UserMenuProps {
	textColor?: string
}

export const UserMenu: FC<UserMenuProps> = ({ textColor }) => {
	const { t } = useTranslation()
	const { status, data: user } = useUser()
	const { isOpen, onOpen, onClose } = useDisclosure()
	if (status === 'loading') {
		return <span>loading...</span>
	}
	if (!user) {
		return <SignInButton variant="link" color={textColor || 'blue.700'} />
	}
	return (
		<>
			<Menu>
				<MenuButton
					as={Button}
					variant="link"
					color={textColor || 'grey.800'}
					display={{ base: 'none', lg: 'flex' }}
				>
					{user.displayName || user.email}
				</MenuButton>
				<MenuList>
					<MenuItem as={Link} to="/my_account/solutions_list?view=all">
						{t('Sparade objekt')}
					</MenuItem>
					<MenuItem as={Link} to={'/my_account/members'}>
						{t('Användare')}
					</MenuItem>
					<SignOutButton as={MenuItem}>{t('Logga ut')}</SignOutButton>
				</MenuList>
			</Menu>
			<Avatar
				as="button"
				src={user.photoURL || ''}
				name={user.displayName || ''}
				bg="green.500"
				width="42px"
				height="42px"
				display={{ base: 'inline-block', lg: 'none' }}
				cursor="pointer"
				onClick={onOpen}
			/>
			<Drawer onClose={onClose} isOpen={isOpen} placement="right">
				<DrawerOverlay></DrawerOverlay>
				<DrawerContent>
					<DrawerCloseButton />
					<DrawerHeader>{user.displayName}</DrawerHeader>
					<DrawerBody display="flex" flexDir="column" px={9} gap={5}>
						<Button
							as={Link}
							variant="link"
							to={'/my_account/solutions_list?view=all'}
							display="flex"
						>
							{t('Sparade objekt')}
						</Button>
						<Button as={Link} variant="link" to={'/my_account/members'}>
							{t('Användare')}
						</Button>
						<SignOutButton as={Button} variant="link" display="flex">
							{t('Logga ut')}
						</SignOutButton>
					</DrawerBody>
				</DrawerContent>
			</Drawer>
		</>
	)
}
