import { useTranslation } from 'react-i18next'

export const useLinks = (): {
	heroLinks: {
		title: string
		link: string
	}[]
	cityLinks: {
		title: string
		link: string
	}[]
	homePageLink: string
} => {
	const { t } = useTranslation()

	const HERO_LINKS = [
		{
			title: t('Våra tjänster'),
			link: '/vara-tjanster',
		},
		{
			title: t('Vad är coworking?'),
			link: t('/content/vad-är-coworking', { ns: 'url' }),
		},
		{
			title: t('Hybridarbete'),
			link: t('/content/hybridarbete', { ns: 'url' }),
		},
		{
			title: t('Hyr ut'),
			link: t('/content/väx-er-coworking', { ns: 'url' }),
		},
	]

	const CITY_LINKS = [
		{
			title: 'Stockholm',
			link: `${t('/lediga-kontor', { ns: 'url' })}/stockholm`,
		},
		{
			title: 'Göteborg',
			link: `${t('/lediga-kontor', { ns: 'url' })}/göteborg`,
		},
		{
			title: 'Malmö',
			link: `${t('/lediga-kontor', { ns: 'url' })}/malmö`,
		},
		{
			title: 'Lund',
			link: `${t('/lediga-kontor', { ns: 'url' })}/lund`,
		},
	]

	return {
		heroLinks: HERO_LINKS,
		cityLinks: CITY_LINKS,
		homePageLink: t('/', { ns: 'url' }),
	}
}
