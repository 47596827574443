import { useTranslation } from 'react-i18next'

type FrontendRoutingLink = {
	to: string
	title: string
}

type ExternalLink = {
	href: string
	title: string
	noFollow?: boolean
	isExternal?: boolean
}

export type Link = FrontendRoutingLink | ExternalLink

export const useLinks = (): {
	internalLinks: Link[]
	mobileFooterLinks: Link[]
	blogLinks: Link[]
	infoLinks: Link[]
} => {
	const { t } = useTranslation()
	const STOCKHOLM_LINKS = {
		ledigaKontorshotell: {
			href: `${t('/lediga-kontorshotell', { ns: 'url' })}/stockholm`,
			title: `${t('Kontorshotell')} Stockholm`,
		},
		ledigaKontor: {
			href: `${t('/lediga-kontor', { ns: 'url' })}/stockholm`,
			title: `${t('Lediga kontor')} Stockholm`,
		},
		coworkingSpaces: {
			href: `${t('/coworking-spaces', { ns: 'url' })}/stockholm`,
			title: `${t('Coworking spaces')} Stockholm`,
		},
	}
	const GOTEBORG_LINKS = {
		ledigaKontorshotell: {
			href: `${t('/lediga-kontorshotell', { ns: 'url' })}/göteborg`,
			title: `${t('Kontorshotell')} Göteborg`,
		},
		coworkingSpaces: {
			href: `${t('/coworking-spaces', { ns: 'url' })}/göteborg`,
			title: `${t('Coworking spaces')} Göteborg`,
		},
	}
	const MALMO_LINKS = {
		ledigaKontorshotell: {
			href: `${t('/lediga-kontorshotell', { ns: 'url' })}/malmö`,
			title: `${t('Kontorshotell')} Malmö`,
		},
		coworkingSpaces: {
			href: `${t('/coworking-spaces', { ns: 'url' })}/malmö`,
			title: `${t('Coworking spaces')} Malmö`,
		},
	}
	const LUND_LINKS = {
		ledigaKontorshotell: {
			href: `${t('/lediga-kontorshotell', { ns: 'url' })}/lund`,
			title: `${t('Kontorshotell')} Lund`,
		},
		coworkingSpaces: {
			href: `${t('/coworking-spaces', { ns: 'url' })}/lund`,
			title: `${t('Coworking spaces')} Lund`,
		},
	}
	const internalLinks: Link[] = [
		STOCKHOLM_LINKS.ledigaKontorshotell,
		GOTEBORG_LINKS.ledigaKontorshotell,
		MALMO_LINKS.ledigaKontorshotell,
		LUND_LINKS.ledigaKontorshotell,
		STOCKHOLM_LINKS.ledigaKontor,
		STOCKHOLM_LINKS.coworkingSpaces,
		GOTEBORG_LINKS.coworkingSpaces,
		MALMO_LINKS.coworkingSpaces,
		LUND_LINKS.coworkingSpaces,
		{
			href: `${t('/lediga-kontorshotell', { ns: 'url' })}/stockholm/södermalm`,
			title: `${t('Kontorshotell')} Södermalm`,
		},
		{
			href: `${t('/lediga-kontorshotell', { ns: 'url' })}/stockholm/östermalm`,
			title: `${t('Kontorshotell')} Östermalm`,
		},
		{
			href: `${t('/lediga-kontorshotell', {
				ns: 'url',
			})}/stockholm/kungsholmen`,
			title: `${t('Kontorshotell')} Kungsholmen`,
		},
		{
			href: `${t('/lediga-kontorshotell', { ns: 'url' })}/stockholm/vasastan`,
			title: `${t('Kontorshotell')} Vasastan`,
		},
		{
			href: `${t('/lediga-kontorshotell', { ns: 'url' })}/stockholm/solna`,
			title: `${t('Kontorshotell')} Solna`,
		},
		{
			href: `${t('/lediga-kontorshotell', { ns: 'url' })}/stockholm/kista`,
			title: `${t('Kontorshotell')} Kista`,
		},
		{
			href: `${t('/lediga-kontorshotell', {
				ns: 'url',
			})}/stockholm/hammarby-sjöstad`,
			title: `${t('Kontorshotell')} Hammarby Sjöstad`,
		},
		{
			href: `${t('/lediga-kontorshotell', { ns: 'url' })}/stockholm/nacka`,
			title: `${t('Kontorshotell')} Nacka`,
		},
	]
	const mobileFooterLinks = [
		STOCKHOLM_LINKS.ledigaKontorshotell,
		GOTEBORG_LINKS.ledigaKontorshotell,
		MALMO_LINKS.ledigaKontorshotell,
	]
	const blogLinks: Link[] = [
		{
			href: '/blogg/nya-kontorshotell-i-stockholm-2023/',
			title: 'Nya kontorshotell 2023',
			isExternal: true,
		},
		{
			href: '/kontorshotell/',
			title: 'Kontorshotell - hur funkar det?',
			isExternal: true,
		},
		{
			href: '/blogg/tag/marknadsrapporter/',
			title: 'Rapporter om coworking',
			isExternal: true,
		},
		{
			href: '/vad-ar-hybridarbete/',
			title: 'Vad är hybridarbete?',
			isExternal: true,
		},
		{
			href: '/hund-pa-jobbet/',
			title: 'Hund på jobbet',
			isExternal: true,
		},
		{
			href: '/blogg/guide-medlemskap-pa-coworking/',
			title: 'Medlemskap på coworking',
			isExternal: true,
		},
		{
			href: '/blogg/guide-hur-fungerar-ett-hyresavtal-pa-kontorshotell/',
			title: 'Guide: hyresavtal på kontorshotell',
			isExternal: true,
		},
		{
			href: '/blogg/virtuella-kontor-och-foretagsadresser-stockholm/',
			title: 'Virtuella kontor',
			isExternal: true,
		},
		{
			href: '/blogg/big-office-flexibla-kontor-for-storre-team/',
			title: 'Flexibla kontor för större team',
			isExternal: true,
		},
		{
			href: '/blogg/hur-skapar-man-en-egen-kultur-pa-sitt-kontorshotell/',
			title: 'Skapa egen kultur på kontorshotell',
			isExternal: true,
		},
		{
			href: '/blogg/tag/guider-att-valja-ratt-kontor/',
			title: 'Guider: kontorshotell & coworking',
			isExternal: true,
		},
	]
	const infoLinks: Link[] = [
		{
			href: 'https://yta.typeform.com/to/e1KySzG1',
			title: 'Nyhetsbrev',
			isExternal: true,
		},
		{
			href: '/blogg',
			title: 'Blogg',
			isExternal: true,
		},
		{
			href: '/om',
			title: 'Om oss',
		},
		{
			href: '/market-reports/',
			title: 'Marknadsrapporter coworking',
			isExternal: true,
		},
	]

	return {
		internalLinks,
		mobileFooterLinks,
		blogLinks,
		infoLinks,
	}
}
