import type { FC, ReactNode } from 'react'
import {
	Box,
	Button,
	Input,
	Text,
	Flex,
	chakra,
	Heading,
	FormControl,
	FormLabel,
} from '@chakra-ui/react'
import { useForm, EmailField, StringField } from '@alexanderathoodly/formify'
import { useSignInUpForm } from './usesigninupform'
import type { UseSignInUpForm } from './usesigninupform'
import { usePasswordResetEmailForm } from './usepasswordresetemailform'
import {
	GoogleSignInButton,
	MicrosoftSignInButton,
} from '@alexanderathoodly/ui-library'
import type { UserCredential } from 'firebase/auth'
import { Trans, useTranslation } from 'react-i18next'

export const FormHeading = chakra('h1', {
	baseStyle: {
		fontSize: '2xl',
		fontWeight: 'semibold',
		borderBottom: '1px',
		borderColor: 'grey.100',
		pb: 2,
		mb: 2,
	},
})

export const FormSection = chakra('section', {
	baseStyle: {
		pb: 7,
		borderBottom: '1px',
		borderColor: 'grey.100',
		mb: 2,
	},
})

export const FormSectionHeading = chakra('h2', {
	baseStyle: {
		fontSize: 'sm',
		color: 'grey.500',
		mb: 4,
	},
})

type EmailPasswordFormProps = {
	action: string
	onSubmit: (email: string, password: string) => void
	setPasswordResetMode?: () => void
}

export const EmailPasswordForm: FC<EmailPasswordFormProps> = ({
	action,
	onSubmit,
	setPasswordResetMode,
}) => {
	const { t } = useTranslation()
	const form = useForm({
		fields: {
			email: EmailField({ required: true }),
			password: StringField({ required: true }),
		},
		onFormSubmit: ({ email, password }) => onSubmit(email, password),
	})
	return (
		<FormControl
			as="form"
			onSubmit={(e) => {
				e.preventDefault()
				form.trySubmit()
			}}
		>
			<Box mb={7}>
				<Input
					name="email"
					type="email"
					placeholder={t('E-post')}
					value={form.getValue('email')}
					onChange={(e) => form.setValue('email', e.target.value)}
					mb={2}
				/>
				<Input
					type="password"
					name="password"
					placeholder={t('Lösenord')}
					value={form.getValue('password')}
					onChange={(e) => form.setValue('password', e.target.value)}
					mb={1}
				/>
				{action == 'Logga in' && (
					<Button variant="link" size="sm" onClick={setPasswordResetMode}>
						{t('Glömt lösenord?')}
					</Button>
				)}
			</Box>
			<Button type="submit" w="full">
				{action}
			</Button>
		</FormControl>
	)
}

const PasswordResetForm: FC = () => {
	const { t } = useTranslation()
	const {
		onSubmit,
		email,
		onChangeEmail,
		isFormValid,
		isSendMailSuccess,
		isSendMailError,
		error,
		formReset,
	} = usePasswordResetEmailForm()

	if (isSendMailError) {
		return (
			<>
				<Heading mb={10}>{t('Ett fel uppstod')}</Heading>
				<Text fontWeight={600} color="red.500">
					{(error as Error).message}
				</Text>
				<Button w="full" mt={6} onClick={formReset}>
					{t('Försök igen')}
				</Button>
			</>
		)
	}

	if (isSendMailSuccess) {
		return (
			<>
				<Heading mb={5}>{t('Ett mejl har sickats till adressen!')}</Heading>
				<Text>
					{t(
						'Det kan ta några minuter tills du får mejlet. Följ instruktionerna på mejlet för att återställa ditt lösenord.'
					)}
				</Text>
			</>
		)
	}

	return (
		<>
			<Text fontSize="sm" color="gray.500" mb={5}>
				{t(
					'Vi skickar ett e-postmeddelande med en länk där du kan återställa ditt lösenord.'
				)}
			</Text>
			<FormHeading>{t('Lösenordsåterställning')}</FormHeading>
			<FormControl my={5}>
				<FormLabel htmlFor="email">{t('E-post')}</FormLabel>
				<Input
					name="email"
					type="email"
					placeholder={t('E-post')}
					value={email}
					onChange={(e) => onChangeEmail(e.target.value)}
				/>
			</FormControl>
			<Button w="full" onClick={onSubmit} disabled={!isFormValid} mt={3}>
				{t('Skicka')}
			</Button>
		</>
	)
}

type SignInUpFormTypeProps = {
	signIn: UseSignInUpForm
	onClick?: (result: UserCredential) => void
}

export const SignInForm: FC<SignInUpFormTypeProps> = ({ signIn, onClick }) => {
	const { t } = useTranslation()
	return (
		<>
			<Text fontSize="sm" color="gray.500" mb={5}>
				{t(
					'Logga in på ditt konto för att lägga till kontor och boka visningar'
				)}
			</Text>
			<FormHeading as="h2">{t('Logga in')}</FormHeading>
			{signIn.error && (
				<Text color="red.500" fontWeight="bold">
					{signIn.error.message}
				</Text>
			)}
			<FormSection pt={3}>
				<FormSectionHeading as="h3">{t('Logga in med')}:</FormSectionHeading>
				<Flex direction="column" gap="8px">
					<GoogleSignInButton
						onClick={async () => {
							const result = await signIn.googleSignIn()
							if (onClick && 'user' in result) {
								onClick(result)
							}
						}}
					/>
					<MicrosoftSignInButton
						onClick={async () => {
							const result = await signIn.microsoftSignIn()
							if (onClick && 'user' in result) {
								onClick(result)
							}
						}}
					/>
				</Flex>
			</FormSection>
			<FormSection pt={3}>
				<FormSectionHeading as="h3">{t('Eller använd')}:</FormSectionHeading>
				<EmailPasswordForm
					action="Logga in"
					onSubmit={async (email: string, password: string) => {
						const result = await signIn.passwordSignIn(email, password)
						if (onClick && 'user' in result) {
							onClick(result)
						}
					}}
					setPasswordResetMode={signIn.toResetPassword}
				/>
			</FormSection>
			<Text fontSize="sm" color="grey.500" mb={3}>
				<Trans
					i18nKey="Har du inget konto, skapa ett konto här"
					defaults="Har du inget konto, <button>skapa ett konto här</button>"
					components={{
						button: (
							<Button variant="link" onClick={signIn.toSignUp} size="sm" />
						),
					}}
				/>
			</Text>
		</>
	)
}

export const SignUpForm: FC<SignInUpFormTypeProps> = ({ signIn, onClick }) => {
	const { t } = useTranslation()
	return (
		<>
			<Text fontSize="sm" color="gray.500" mb={5}>
				{t(
					'Skapa ett konto kostnadsfritt för att spara kontor och boka visningar.'
				)}
			</Text>
			<FormHeading as="h2">{t('Skapa ett konto')}</FormHeading>
			{signIn.error && (
				<Text color="red.500" fontWeight="bold">
					{signIn.error.message}
				</Text>
			)}
			<FormSection pt={3}>
				<Flex direction="column" gap="8px">
					<GoogleSignInButton
						onClick={async () => {
							const result = await signIn.googleSignIn()
							if (onClick && 'user' in result) {
								onClick(result)
							}
						}}
					/>
					<MicrosoftSignInButton
						onClick={async () => {
							const result = await signIn.microsoftSignIn()
							if (onClick && 'user' in result) {
								onClick(result)
							}
						}}
					/>
				</Flex>
			</FormSection>
			<FormSection pt={3}>
				<FormSectionHeading as="h3">
					{t('Eller använd e-post och lösenord')}:
				</FormSectionHeading>
				<EmailPasswordForm
					action="Skapa konto"
					onSubmit={async (email: string, password: string) => {
						const result = await signIn.passwordSignUp(email, password)
						if (onClick && 'user' in result) {
							onClick(result)
						}
					}}
				/>
			</FormSection>
			<Text fontSize="sm" color="grey.500" mb={3}>
				<Trans
					i18nKey="Har du redan ett konto, logga in här"
					defaults="Har du redan ett konto, <button>logga in här</button>"
					components={{
						button: (
							<Button variant="link" onClick={signIn.toSignIn} size="sm" />
						),
					}}
				/>
			</Text>
		</>
	)
}

export const LinkCredentials: FC<SignInUpFormTypeProps> = ({ signIn }) => {
	const { t } = useTranslation()
	let authMethod: ReactNode = null
	switch (signIn.pending?.signInMethod) {
		case 'google.com':
			authMethod = <GoogleSignInButton onClick={signIn.googleSignIn} />
			break
		case 'microsoft.com':
			authMethod = <MicrosoftSignInButton onClick={signIn.microsoftSignIn} />
			break
		case 'password':
			authMethod = (
				<EmailPasswordForm action="Logga in" onSubmit={signIn.passwordSignIn} />
			)
			break
	}
	return (
		<Box>
			{signIn.error && (
				<Text color="red.500" fontWeight="bold">
					{signIn.error.message}
				</Text>
			)}
			<Text>{t('Det ser ut att du redan har ett konto med oss.')}</Text>
			<Text>
				{t(
					'Vänligen logga in med din befintlig autentiseringsmetod för att verifiera din identitet. Efter det ska du kunna logga in med båda gamla och nya metoden.'
				)}
			</Text>
			{authMethod}
		</Box>
	)
}

export const ResetPassword: FC<SignInUpFormTypeProps> = ({ signIn }) => {
	return (
		<>
			<PasswordResetForm />{' '}
			<Text fontSize="sm" color="grey.500" mb={3} mt={5}>
				<Trans
					i18nKey="Har du redan ett konto, logga in här"
					defaults="Har du redan ett konto, <button>logga in här</button>"
					components={{
						button: (
							<Button variant="link" onClick={signIn.toSignIn} size="sm" />
						),
					}}
				/>
			</Text>
		</>
	)
}

type SignInUpFormProps = {
	defaultType: 'signIn' | 'signUp'
	onClick?: (result: UserCredential) => void
}

export const SignInUpForm: FC<SignInUpFormProps> = ({
	defaultType,
	onClick,
}) => {
	const signIn = useSignInUpForm({ defaultType })
	if (signIn.type === 'signIn') {
		if (onClick) {
			return <SignInForm signIn={signIn} onClick={onClick} />
		}
		return <SignInForm signIn={signIn} />
	}
	if (signIn.type == 'resetPassword') {
		return <ResetPassword signIn={signIn} />
	}
	if (signIn.type === 'signUp') {
		if (onClick) {
			return <SignUpForm signIn={signIn} onClick={onClick} />
		}
		return <SignUpForm signIn={signIn} />
	}
	if (signIn.type === 'linkCredentials') {
		return <LinkCredentials signIn={signIn} />
	}
	return null
}
